import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import Alert from '../../../Services/Alert';

export class ScorePicker extends Component {
    constructor(props) {
        super(props);

        this.disabled = props.disabled || false;
        this.updateValue = this.updateValue.bind(this);

        this.max = props.max ? props.max : 4
        
        this.state = {
            value: props.initValue || 0,
            marks: [],
        };
    }

    componentDidMount() {
        this.genMarks();
        this.controlValue();
    }

    componentDidUpdate (prevProps, _prevState) {
        if (prevProps.allowedScoreValues !== this.props.allowedScoreValues) {
            this.genMarks();
            this.controlValue();
        }
    }

    genMarks() {
        const marks = [];
        for (let i = 0; i <= this.max; i++) {
            marks.push({
                value: i,
                label: this.genLabel(i),
            })
        }
        this.setState({ marks });
    }

    genLabel(value) {
        const strVal = value === 0 ? 'N/A' : value.toString();
        if (this.props.allowedScoreValues?.length && !this.props.allowedScoreValues.includes(value)) {
                return <em className='disabled'>{strVal}</em>;
        } else if (this.props.allowedScoreValues?.length && this.props.allowedScoreValues.includes(value)) {
            return <strong>{strVal}</strong>;
        }

        return strVal;
    }

    controlValue () {
        if (this.props.allowedScoreValues?.length && !this.props.allowedScoreValues.includes(this.state.value)) {
            const value = Math.max(...this.props.allowedScoreValues);
            this.setState({ value });
            if (this.props.onChange) {
                this.props.onChange(value);
            }
        }
    }

    showError (message) {
        Alert.show({
            message,
            type: "error",
            noClickaway: true, // MuiSlider déclenche des clics supplémentaires qui exécutent ClickAwayListener de Alert, donc on le desactive pour que l'Alert reste affichée.
        });
    }

    updateValue(value) {
        if (this.props.allowedScoreValues && !this.props.allowedScoreValues.includes(value)) {
            this.showError("Error : this score is not consistent with the severity of the related notice or the absence of notice/remediation action.");
            return; // on ne met pas à jour la valeur actuelle
        }

        this.setState({ value: value });
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        return (
            <div>
                <Slider
                    disabled={this.disabled}
                    onChange={(e, value) => this.updateValue(value)}
                    className="slider-score-mra"
                    value={this.state.value}
                    step={null}
                    min={0}
                    max={this.max}
                    marks={this.state.marks}
                />
            </div>
        );
    }
}
ScorePicker.propTypes = {
    allowedScoreValues: PropTypes.arrayOf(PropTypes.number),
    max: PropTypes.number,
    /** NB : attention, cette valeur "contrôle" le composant, ce n'est pas juste la valeur initiale */
    initValue: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
}
