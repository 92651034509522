import RouteStore, {Route} from "../../Store/RouteStore";
import React from "react";
import Export from "../Export/Export";
import ReviewExport from "./ReviewExport";
import ExportDownload from "./ExportDownload";

RouteStore.routes.exportReview = new Route({
    path: '/export/review',
    component: (props) => <ReviewExport {...props}/>,
    menuItem: {
        title: 'Export'
    }
});
RouteStore.routes.exportDownload = new Route({
    path: '/export/download/:id/:documentName',
    component: (props) => <ExportDownload {...props}/>,
    menuItem: {
        title: 'Export download'
    }
});
