import React, {Component} from 'react';
import {observer} from "mobx-react";
import {File} from "../Forms/File/File";
import Alert from "../../Services/Alert";
import Http from "../../Services/Http";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
import String from "../../Services/String";
import AppBarStore from "../../Store/AppBarStore";
import User from "../../Services/User/User";
import { ActionButton } from '../Modal/ActionButton';
import { ButtonBar } from '../Modal/ButtonBar';
import { Icon } from '@material-ui/core';
import Modal from "../../Services/Modal";

class ImportAsync extends Component{

    constructor(props){
        super(props);
        AppBarStore.title = 'Import';
        this.save = this.save.bind(this);
        this.state = {
            file: null,//File to upload
            fileName: null,//FileName to use on applying duplication progress
            progress: false,
            default: false,
            message: null,
            messageStatus: null,
            running: undefined,
        };
    }

    async componentDidMount(){
        let response = await Http.get(`import/${this.props.resource}/running`) || {};
        if(response.status === 'OK'){
            this.setState({running: response.running});
        }
        else {
            console.error(`unexpected response from import/${this.props.resource}/running`, response);
        }
    }

    onDrop(files, error){
        if(error === false && files.length === 1){
            this.setState({file: files[0]});
        }
    }

    save(){
        if(this.state.file === null){
            Alert.show({message: 'Please drop or select a file', type: 'error'});
            return;
        }
        this.setState({progress: true, message: null, messageStatus: null});
        Http.postFile(`import/${this.props.resource}`, {file: this.state.file}).then((response) => {
            if(response.status === 'ERROR'){
                Alert.show({ message : response.message, type: 'error'});
            }else if(response.status === 'OK'){
                // Using message from response :
                this.setState({running: true});
                Alert.show({ message : response.message, type: 'success'});
            }
            Modal.close();
            this.setState({progress: false});
        }).catch((_error) => {
            Modal.close();
            this.setState({progress: false, message: null, messageStatus: null});
            Alert.show({message: 'Something went wrong, please try again', type: 'error'});
        });
    }

    render() {
        return (
            <Grid className={'import container'}>
                <div className='import_progress_container'>
                    {this.state.running === undefined && <p>Checking if an import is already running</p>}
                    {this.state.running === true && this.state.message === null && <p><Icon class="fas fa-exclamation-triangle" /> An import is already running</p>}
                </div>

                {this.state.message && (
                    <div
                        className={'alert alert-' + (this.state.messageStatus ? this.state.messageStatus : 'primary')}
                        role="alert"
                    >
                        {String.nlToBr(this.state.message)}
                    </div>
                )}
                {this.state.progress || this.state.running === undefined || this.state.running ? (
                    <div className='import_progress_container'>
                        <CircularProgress />
                        <>
                            {this.state.progress && <p>Uploading ....</p>}
                        </>
                    </div>
                ) : (
                    <div>
                        <File
                            fileTypes={['xlsx']}
                            callback={(files, error) => {
                                this.onDrop(files, error);
                            }}
                            maxFile={1}
                        />
                        <ButtonBar>
                            <ActionButton onClick={this.save.bind(this)}>
                                Import
                            </ActionButton>
                        </ButtonBar>
                    </div>
                )}
            </Grid>
        );
    }
}

export default observer(ImportAsync);