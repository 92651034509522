import React, {Component} from 'react';
import {observer} from "mobx-react";
import {File} from "../Forms/File/File";
import Button from "@material-ui/core/Button";
import Alert from "../../Services/Alert";
import Http from "../../Services/Http";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
import String from "../../Services/String";
import User from "../../Services/User/User";

class ReviewImport extends Component{

    constructor(props){
        super(props);
        this.save = this.save.bind(this);
        this.state = {
            file: null,//File to upload
            fileName: null,//FileName to use on applying duplication progress
            progress: false,
            message: null,
            messageStatus: null
        };
    }

    onDrop(files, error){
        if(error === false && files.length === 1){
            this.setState({file: files[0]});
        }
    }

    save(){
        if(this.state.file === null){
            Alert.show({message: 'Please drop or select a file', type: 'error'});
            return;
        }
        this.setState({progress: true, message: null, messageStatus: null});
        let logoutInterval = setInterval(() => {
            User.lastActivity = new Date();
        }, 1000);
        Http.postFile('import/review', {file: this.state.file}).then((response) => {
            if(response.status === 'ERROR'){
                this.setState({message: response.message, messageStatus: 'error'});
            }else if(response.status === 'OK'){
                let message = "";
                if(response.added > 0){
                    message += response.added+" review"+(response.added > 1 ? 's':'')+" successfully added.";
                }
                if(response.updated > 0){
                    message += (message === "" ? "" : "\n")+response.updated+" review"+(response.updated > 1 ? 's':'')+" successfully updated.";
                }
                if(message === ""){
                    message = response.message;
                }
                this.setState({message: message, messageStatus: 'success'});
            }
            this.setState({progress: false});
            clearInterval(logoutInterval);
        }).catch((error) => {
            this.setState({progress: false, message: null, messageStatus: null});
            Alert.show({message: 'Something went wrong, please try again', type: 'error'});
            clearInterval(logoutInterval);
        });
    }

    render() {
        return (
            <Grid className={'import container'}>
                <h1>Reviews</h1>
                {this.state.message ?
                    <div className={"alert alert-" + (this.state.messageStatus ? this.state.messageStatus : 'primary')} role="alert">
                        {String.nlToBr(this.state.message)}
                    </div>
                : null }
                { this.state.progress ?
                    <div className={"import_progress_container"}>
                        <CircularProgress />
                        <p>Please wait....</p>
                    </div>
                    :
                    <div>
                        <File fileTypes={['xlsx']} callback={(files, error) => {this.onDrop(files, error);}} maxFile={1} />
                        <Button variant="contained" color="secondary" className="save button-general" onClick={this.save.bind(this)}>
                            Import
                        </Button>
                    </div>
                }
            </Grid>
        )
    }
}

export default observer(ReviewImport);
