import React, {Component} from "react";
import PropTypes from 'prop-types';
import Modal from '../../Modal';
import FormControl from '@material-ui/core/FormControl/index';
import TextField from '@material-ui/core/TextField/index';
import './bulk-edit.css';
import Http from '../../Http';
import Alert from '../../Alert';
import FieldProviderStore from "../../APIResource/FieldProviders/__FieldProviderStore";
import { userHasRoleADMIN } from '../../../Store/ParameterStore';
import { ActionButton } from "../../../Components/Modal/ActionButton";
import { openLoadingModal, validateIds } from "../bulkUtils";
import { Checkbox, FormControlLabel } from "@material-ui/core";

export class BulkEdit{
    getId(){
        return 'bulkEditing'
    }

    getLabel(){
        return 'Bulk Edit'
    }

    /**
     * @param {import('../../APIResource/APIResource').APIResourceBulkParams} params 
     */
    setParameters(params){
        this.parameters = params;
    }

    /**
     * @param {Array} ids 
     * @param {*} parent - DataTable en cours
     */
    async run(ids, parent, params = {}){
        this.ids = ids;
        const errorMessage = this.parameters.forbiddenAccessMessage || 'Bulk edit unavailable : Your selection can only include items you have the right to edit.';

        let openModal = () => {
            Modal.open({
                title: 'Bulk Edit',
                content: (
                    <BulkEditingForm
                        fields={this.parameters.fields}
                        ids={this.ids}
                        parent={parent}
                        resource={this.parameters.resource}
                        selectAll={params?.selectAll}
                        callback={this.parameters?.callback}
                        callbackNeededFields={this.parameters?.callbackNeededFields}
                    />
                ),
            });
        };

        openLoadingModal();

        if (
            await validateIds(
                ids,
                this.parameters.itemAccessCondition,
                this.parameters.resource,
                this.parameters.itemAccessConditionEntityFields,
                this.parameters.bulkValidateRoles
            )
        ) {
            openModal();
        } else {
            Modal.close();
            Alert.show({ message: errorMessage, type: 'warning' });
        }
    }
}

export class BulkEditingForm extends Component{
    constructor(props){
        super(props);
        /** @type {{resource: import('../../APIResource/APIResource').APIResource}} */
        this.props;

        this.state = {
            fields: {},
            progress: false,
            nullFields: [],
        }

        this.changedFields = {};
    }

    componentIsControlled(fieldId) {
        if (fieldId === 'changeLogComment') return false;

        const field = this.props.fields[fieldId];
        return !["text", "textarea"].includes(field.type);
    }

    handleChange(fieldId, value){
        if (this.componentIsControlled(fieldId)) {
            // Pour les composants contrôlés, il faut que l'on remplisse le state, pour les autres non
            let fields = this.state.fields;
            fields[fieldId] = value;
            this.setState({fields: fields});
        }
        if (value === '' || value === null || (Array.isArray(value) && !value.length)) {
            if (userHasRoleADMIN()) {
                this.changedFields[fieldId] = value;
            } else {
                delete this.changedFields[fieldId];
            }
        }
        else {
            this.changedFields[fieldId] = value;
        }
    }

    save(){
        this.setState({progress: true});
        Http.post(this.props.resource.resourceId + '/bulk/edit', {
            ids: this.props.ids,
            fields: this.changedFields,
            changeLogComment: this.changedFields.changeLogComment
        }).then((response) => {
            // Pour les besoins du callback on s'assure que les informations modifiées sont bien
            // disponibles dans la ressource alors qu'elles ne sont pas nécessairement affichées dans DataTable.
            this.props.parent.props.resource.operations.list.neededFields = [
                ...(this.props.parent.props.resource.operations.list?.neededFields || []),
                ...Object.keys(this.changedFields),
                ...(this.props?.callbackNeededFields || []),
            ];
            this.props.parent.refreshApiData({forceReload: true})
                .then(() => {
                    if (this.props.callback) {
                        this.props.callback(this.props.ids, this.changedFields, this.props.parent.props.resource);
                    }
                    else {
                        Modal.close();
                    }
                    this.props.parent.setState({selection: [], progress: false})
                });
            Alert.show({message: response.message || 'Bulk Edit successfully processed.', type: "success"});
        });
    }

    genForm(){
        let fields = [];
        for(let i in this.props.fields){
            let field = this.props.fields[i];
            const fieldId = i;
            let component = null;
            field.issueButton = false;
            field.required = false;

            const currentValue = this.componentIsControlled(fieldId) ? this.state.fields[fieldId] : this.changedFields[fieldId];

            /** @todo utiliser editComponent et tester les displayCondition pour les entités modifiées, plutôt que de passer les "bulkEntities" */
            if(field.edit){
                component = field.edit(field, currentValue, (value) => {this.handleChange(fieldId, value)}, this.changedFields, {}, "bulkEdit", this.props.resource.items.filter((item) => this.props.ids.includes(item.id)));
            }
            else if(FieldProviderStore[field.type] && FieldProviderStore[field.type].getEdit){
                component = FieldProviderStore[field.type].getEdit(field, currentValue, (value) => {this.handleChange(fieldId, value)}, this.changedFields, {});
            }
            else if(!field.component){
                component =
                    <div key={i}>
                        <FormControl>
                            <TextField
                                id={'field-' + i}
                                label={field.title}
                                className={'field-' + i}
                                onChange={(event) => {this.handleChange(i, event.target.value)}}
                                value={currentValue ?? ''}
                            />
                        </FormControl>
                    </div>
                ;
            }
            else{
                component = field.component((value) => {this.handleChange(i, value)});
            }
            if (component !== undefined) fields.push(
                <div className="field" key={`nullable-${i}`}>
                    {this.state.nullFields.includes(fieldId) ? <TextField label={field.title} value={currentValue} disabled={true} /> : component}
                    {userHasRoleADMIN() && <FormControlLabel
                        style={{ marginLeft: 10 }}
                        control={
                            <Checkbox
                                checked={this.state.nullFields.includes(fieldId)}
                                onChange={(event, checked) => {
                                    this.setState({nullFields: checked ? [...this.state.nullFields, fieldId] : this.state.nullFields.filter(f => f !== fieldId)});
                                    if (checked) {
                                        this.handleChange(fieldId, field?.params?.multi ? [] : null)
                                    } else {
                                        if (this.componentIsControlled(fieldId)) {
                                            const fields = { ...this.state.fields};
                                            delete fields[fieldId];
                                            this.setState({fields});    
                                        }
                                        delete this.changedFields[fieldId];
                                        
                                    }
                                }}
                            />
                        }
                        label={<span>null</span>}
                    />}
                </div>
            );
        }

        let bottomFields = []
        Object.entries({
            changeLogComment: {title: 'Justification of the data update', type: 'text', rows: 3,  display: () => null, displayList: () => null}
        }).map(([ fieldId, field ]) => {
            const changeLogComment = this.changedFields.changeLogComment || '';
            bottomFields.push(<div className="field changelog" key={fieldId}>
                {FieldProviderStore[field.type].getEdit(field, changeLogComment, (value) => {
                    this.handleChange(fieldId, value)
                }, this.changedFields)}
            </div>)
        });

        return(
            <div className="bulk-edit">
                <div className="fields main-fields"> {/* grand padding pour éviter que la dernière entity select ne soit invisible */}
                    {fields}
                </div>
                <div className="fields">
                    {bottomFields}
                    <ActionButton 
                        onClick={this.save.bind(this)}
                        loading={this.state.progress}
                    >
                        Apply to {this.props.ids.length} {this.props.resource.resourceId}
                    </ActionButton>
                </div>

            </div>
        );
    }

    render(){
        return(this.genForm());
    }
}
BulkEditingForm.propTypes = {
    ids: PropTypes.array,
    parent: PropTypes.any,
    resource: PropTypes.any,
    fields: PropTypes.object,
    /** Si appelé, ne ferme pas la Modal en cours. */
    callback: PropTypes.func,
    callbackNeededFields: PropTypes.arrayOf(PropTypes.string),
}
