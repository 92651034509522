import Http from '../../Http';
import Alert from "../../Alert";
import React, {Component} from "react";
import Modal from "../../Modal";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {DatePicker} from "../../../Components/Forms/DatePicker/DatePicker";
import Grid from "@material-ui/core/Grid";
import {userHasRoleMRM} from "../../../Store/ParameterStore";
import {Icon} from "@material-ui/core";

export class ModelExport{
    getId(){
        return 'modelCsvExport'
    }

    getLabel(){
        return 'Model export'
    }

    setParameters(params){
        this.parameters = params;
    }

    run(ids, params = null){
        this.ids = ids;

        Modal.open({
            title: 'Model export',
            content: <ModelExportForm ids={this.ids}/>
        });
    }
}


export class ModelExportForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            progress: false,
            date: null,
            running: undefined
        };
    }

    async componentDidMount(){
        let response = await Http.get('export/model/running') || {};
        if(response.status === 'OK'){
            this.setState({running: response.running, date: new Date()});
        }
        else {
            console.error("unexpected response from export/model/running", response);
        }
    }

    save(){
        this.setState({progress: true});
        Http.post('models/export', {ids: this.props.ids, date: this.state.date}).then((response) => {
            if(response.status === 'ERROR'){
                Alert.show({ message : response.message, type: 'error'});
            }else if(response.status === 'OK'){
                if (response.route && response.fileName) {
                    Http.openFile(response.route, response.fileName);
                }else if(response.documentId && response.documentName){
                    Http.openEncryptedFile(
                        "/document/download/" +
                        response.documentId,
                        response.documentName
                    )
                }else if(response.message){
                    Alert.show({ message : response.message, type: 'success'});
                }
            }else{
                Alert.show({ message : "File can't be downloaded", type: 'warning'});
            }
            Modal.close();
            this.setState({progress: false});
        });
    }

    render() {
        return (
            <Grid className={'export container'}>
                <div className='export_progress_container'>
                    {this.state.running === undefined && <p>Checking if an export is already running</p>}
                    {this.state.running === true && <p><Icon class="fas fa-exclamation-triangle" /> An export is already running</p>}
                </div>

                { this.state.progress && this.state.running === false &&
                    <div className={"export_progress_container"}>
                        <CircularProgress />
                        <p>Please wait....</p>
                    </div>
                }
                { !this.state.progress && this.state.running === false &&
                    <div>
                        {userHasRoleMRM() ?
                        <DatePicker label={"Export inventory as of"} value={this.state.date} onChange={(value) => this.setState({date: value})} minDate={new Date("2021-05-20")}/>
                        : null}
                        <Button style={{marginTop: 10}} variant="contained" color="secondary" className="save button-general" onClick={this.save.bind(this)}>
                            Export
                        </Button>
                    </div>
                }
            </Grid>
        )
    }
}