import React from 'react';
import { APIResource } from '../Services/APIResource/APIResource';
import User from '../Services/User/User';
import { ChangeLog } from '../Components/Display/ChangeLog/ChangeLog';
import { BulkVerify } from '../Services/BulkActions/BulkVerify/BulkVerify';
import { userHasRoleADMIN, userHasRoleMRM } from '../Store/ParameterStore';

const userIsManagerOfScope = (user, scope) => {
    // Quelques tests différents pour le même résultat, pour ne pas avoir à attendre
    // le refresh de l'user si on a déjà les infos du scope.
    if (typeof scope === 'string'){
        let iri = scope;
        return user.profile.managedScopes.includes(iri);
    }
    else if (scope.managers !== undefined) {
        return scope.managers.includes(user.profile.path);
    }
    else if (scope['@id'] !== undefined) {
        let iri = scope['@id'];
        return user.profile.managedScopes.includes(iri);
    }
    else {
        return false
    }
};

const STD_CAN_EDIT = ['title', 'description', 'managers', 'parentScope', 'followers'];
const MRM_CAN_EDIT = [
    'uid',
    'title',
    'description',
    'managers',
    'parentScope',
    'followers',
    'verified',
    'mrmTeam',
];

const STD_REQUIRED = ['title', 'managers', 'parentScope'];

const canEdit = (entity, entity2, key, context, fieldId) => {
    return context === 'edit'
        ? STD_CAN_EDIT.includes(fieldId) || (userHasRoleMRM() && MRM_CAN_EDIT.includes(fieldId)) || userHasRoleADMIN()
        : true;
};

/**
 * On force certains champs requis pour les utilisateurs STD, pas pour les autres.
 * @param {Object} entity
 * @param {string} fieldId
 * @returns {boolean}
 */
const isRequired = (entity, fieldId) => {
    return userHasRoleMRM() || userHasRoleADMIN() ? false : STD_REQUIRED.includes(fieldId);
};

export const ApiResourceDefaultParams = {
    id: 'scopes',
    instanceId: 'scopes',
    name: 'Teams',
    fieldForTitle: 'title',
};

export default class ScopeAdmin {
    constructor() {
        this.configure();
    }

    async configure() {
        // Propriétés qu'on retrouve dans tous les champs
        const defaultProperties = {
            displayConditions: canEdit,
            required: isRequired,
        };

        /** 
         * Ce chargement est indispensable pour remplir APIResourceStore
         * dont dépend notamment ReviewAdmin.
         */
        let scopes = new APIResource(ApiResourceDefaultParams);
        let teams = new APIResource({
            id: 'scopes',
            instanceId: 'teams',
            name: 'Teams',
            fieldForTitle: 'title',
        });

        // On a besoin du path de l'utilisateur pour l'affichage liste donc on attend.
        if (!User.profile || !User.profile.path) await User.restore();

        /**
         * NB: si le temps de réponse du serveur est trop lent, l'utilisateur STD
         *     peut voir la liste complète avant la mise à jour.
         *     Pour corriger il faudrait probablement faire une nouvelle APIResource,
         *     mais cela peut donner des problèmes de performance.
         */
        [scopes, teams].map(scopes => scopes
            .setFields({
                id: {
                    title: 'ID',
                    ...defaultProperties,
                },
                uid: {
                    title: 'Unique ID',
                    type: 'text',
                    doNotResetValueWhenNotDisplayed: true,
                    ...defaultProperties,
                },
                systemId: {
                    title: 'System ID',
                    type: 'text',
                    maxLength:255, 
                    doNotResetValueWhenNotDisplayed: true,
                    ...defaultProperties,
                },
                title: {
                    title: 'Title',
                    type: 'text',
                    maxLength:255, 
                    helperTextDisplay: (entity) => {
                        if (entity.verified === false) {
                            return {
                                type: 'warning',
                                icon: 'fa-exclamation-triangle',
                                text: 'Unverified',
                            };
                        }
                    },
                    ...defaultProperties,
                },
                shortName: {
                    title: 'Short name',
                    doNotResetValueWhenNotDisplayed: true,
                    ...defaultProperties
                },
                description: {
                    title: 'Description',
                    type: 'textarea',
                    ...defaultProperties,
                },
                managers: {
                    title: 'Managers',
                    type: 'user',
                    params: {
                        resource: 'users',
                        instanceId: 'users_val',
                        displayField: 'toString',
                        editDisplayField: 'fullNameWithTeam',
                        sortField: 'lastName',
                        multi: true,
                        links: false,
                        endpoints: {
                            getAll: 'users/all-users/val',
                        },
                    },
                    ...defaultProperties,
                },
                parentScope: {
                    title: 'Parent team',
                    type: 'entityTree',
                    params: {
                        resource: 'scopes',
                        instanceId: 'scopes_all',
                        displayField: 'title',
                        multi: false,
                        links: true,
                        linkPath: (entity) => `/resource/scopes/${entity.id}/detail`,
                        childrenPropertyName: 'childScopes',
                    },
                    ...defaultProperties,
                },
                isMrmTeam: {
                    title: 'Is MRM Team',
                    type: 'bool',
                    doNotResetValueWhenNotDisplayed: true,
                    ...defaultProperties
                },
                mrmTeam: {
                    title: 'MRM Team',
                    type: 'entity',
                    params: {
                        resource: 'scopes',
                        instanceId: 'scopes_mrm',
                        displayField: 'title',
                        endpoints: {
                            getAll: 'scopes/mrm',
                        },
                    },
                    doNotResetValueWhenNotDisplayed: true,
                    ...defaultProperties,
                },
                customTeam: {
                    title: 'Custom',
                    type: 'bool',
                    helperTextDisplay: 'Team created by VAL or MRM which is not based on RPG directory',
                    doNotResetValueWhenNotDisplayed: true,
                    ...defaultProperties,
                },
                verified: {
                    title: 'Verified',
                    type: 'bool',
                    helperTextDisplay: 'Team listed in the RPG tree, available to the users',
                    helperTextEdit: 'Team listed in the RPG tree, available to the users',
                    doNotResetValueWhenNotDisplayed: true,
                    ...defaultProperties,//
                },
                outOfRepository: {
                    title: 'Out of repository',
                    type: 'bool',
                    helperTextDisplay: 'Team which is no longer used in RPG',
                    doNotResetValueWhenNotDisplayed: true,
                    ...defaultProperties,//
                },
                followers: {
                    title: 'Followers',
                    type: 'userAsync',
                    params: {
                        resource: 'users',
                        instanceId: 'users_all',
                        displayField: 'toString',
                        sortField: 'lastName',
                        editDisplayField: 'fullNameWithTeam',
                        multi: true,
                        links: false,
                        endpoints: {
                            getAll: 'users/all-users/all',
                        },
                    },
                    ...defaultProperties,
                },
                department: {
                    title: 'Department',
                    type: 'entity',
                    ...defaultProperties,
                    params: {
                        resource: 'departments',
                        displayField: 'name',
                    },
                    doNotResetValueWhenNotDisplayed: true,
                },
                changeLogsEntities: {
                    title: 'Audit trail',
                    display: (field, value, entity, props) => (
                        <ChangeLog
                            field={field}
                            values={value}
                            entity={entity}
                            entityResource={'scopes'}
                            props={props}
                        />
                    ),
                    displayList: () => null,
                    ...defaultProperties,
                },

                //Additional fields without relation with Scope
                changeLogComment: {
                    title: 'Justification of the data update',
                    type: 'textarea',
                    display: () => null,
                    displayList: () => null,
                    token: false,
                    ...defaultProperties,
                },
            })
            .setLayout({
                tabs: {
                    Scope: {
                        rows: [
                            {
                                panels: {
                                    Identification: {
                                        cols: 6,
                                        fields: ['id', 'uid', 'systemId', 'title', 'shortName', 'description'],
                                    },
                                    Properties: {
                                        cols: 6,
                                        fields: [
                                            'managers',
                                            'type',
                                            'parentScope',
                                            'isMrmTeam',
                                            'mrmTeam',
                                            'customTeam',
                                            'verified',
                                            'outOfRepository',
                                            'followers',
                                            'department',
                                        ],
                                    },
                                },
                            },
                        ],
                    },
                    'Audit trail': {
                        rows: [
                            {
                                panels: {
                                    'Audit trail': {
                                        cols: 12,
                                        fields: ['changeLogsEntities', 'changeLogComment'],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genListView({
                fields: [
                    'uid',
                    'systemId',
                    'title',
                    'shortName',
                    'parentScope',
                    'description',
                    'verified',
                    'outOfRepository',
                ],
                // Tout le monde a accès, mais un userSTD ne voit que les scopes dont il est manager
                permanentFilters: {
                    ...(userHasRoleMRM() || userHasRoleADMIN()
                        ? {}
                        : { 'managers[]': User.profile.path }),
                },
            })
            .genInsertView({
                fields: [
                    'uid',
                    'systemId',
                    'title',
                    'shortName',
                    'description',
                    'parentScope',
                    'verified',
                    'outOfRepository',
                    'followers',
                    'customTeam',
                    'managers',
                    'mrmTeam',
                ],
                menuItem: { title: 'Add' },
                onInit: ({ entity, _resource, _context }) => {
                    entity['@type'] = 'Scope';
                    entity.customTeam = true;
                    if (entity.managers === undefined) entity.managers = [];
                    entity.managers.push(User.profile.path);
                },
                onUpdate: (fieldId, oldValue, newValue, entity) => {
                    // Pour éviter que l'utilisateur STD puisse s'enlever :
                    if (
                        !userHasRoleADMIN() &&
                        !userHasRoleMRM() &&
                        entity.managers !== undefined &&
                        !entity.managers.includes(User.profile.path)
                    )
                        entity.managers.push(User.profile.path);
                },
            })
            .genEditView({
                fields: [
                    'uid',
                    'systemId',
                    'title',
                    'shortName',
                    'description',
                    'parentScope',
                    'verified',
                    'outOfRepository',
                    'followers',
                    'department',
                    'customTeam',
                    'managers',
                    'mrmTeam',
                ],
                itemAccessCondition: (entity) =>
                    userHasRoleADMIN() || userHasRoleMRM() || userIsManagerOfScope(User, entity),
                onUpdate: (fieldId, oldValue, newValue, entity) => {
                    // Pour éviter que l'utilisateur STD puisse s'enlever :
                    if (
                        !userHasRoleADMIN() &&
                        !userHasRoleMRM() &&
                        entity.managers !== undefined &&
                        !entity.managers.includes(User.profile.path)
                    )
                        entity.managers.push(User.profile.path);
                },
            })
            .genDetailView({
                fields: [
                    'id',
                    'uid',
                    'systemId',
                    'title',
                    'shortName',
                    'description',
                    'parentScope',
                    'verified',
                    'outOfRepository',
                    'followers',
                    'department',
                    'customTeam',
                    'managers',
                    'changeLogsEntities',
                    'changeLogComment',
                    'mrmTeam',
                ],
                onInit: () => User.restore(),
                itemAccessCondition: (entity) =>
                    userHasRoleADMIN() || userHasRoleMRM() || userIsManagerOfScope(User, entity),
            })
            .allowDelete({
                itemAccessCondition: (entity) =>
                    userHasRoleADMIN() || userHasRoleMRM() || userIsManagerOfScope(User, entity),
            })
        );
        if (userHasRoleMRM() || userHasRoleADMIN()) {
            [scopes, teams].map(scopes => scopes.addBulkAction(BulkVerify, {
                resource: scopes,
                icon: 'check',
                resourceId: 'scopes',
            }));
        }
    }
}
