import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Button } from '@material-ui/core';
import { APIResource } from '../../Services/APIResource/APIResource';
import { EntityForm } from '../Forms/EntityForm/EntityForm';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { getIdFromIri } from '../../Services/utils';
import MRA, { MRA_PROCESS, getUserRole } from '../../Services/MRA';
import Alert from '../../Services/Alert';
import { ButtonBar } from '../Modal/ButtonBar';
import { ActionButton } from '../Modal/ActionButton';
import { getParamBySystemId } from '../../Store/ParameterStore';
import { hasIncoherentModels, openIncoherentModelsInformationModal } from './Display/IncoherentModelsInformationModal';
import Modal from '../../Services/Modal';

let entityTypeMap = { 0: 'Model', 1: 'Review' };

export const Init = ({ initialModelId = null, initialReviewId = null, initialModels = null, process = null, mraSource = null, context = null }) => {
    let location = useLocation();
    const modelResource = new APIResource({
        id: 'models',
        name: 'Model',
    });

    const reviewResource = new APIResource({
        id: 'reviews',
        name: 'Review',
    });
    const dimensionResource = new APIResource({
        id: 'parameters',
        name: 'Dimension',
    });
    const [modelId, setModelId] = useState(location.state ? location.state.modelId : initialModelId);
    const [reviewId, setReviewId] = useState(location.state ? location.state.reviewId : initialReviewId);
    const [models, setModels] = useState(location.state ? location.state.models : initialModels);
    const [role, setRole] = useState(initialModels ? 'LoD2' : null);
    const [ready, setReady] = useState(false);
    const [dimension, setDimension] = useState();
    // ask to close modal
    const [askToClose, setAskToClose] = useState(false);

    const setSource = (entityType, entityIri) => {
        if (entityTypeMap[entityType] === 'Model') {
            setReviewId(null);
            setModelId(getIdFromIri(entityIri));
        }
        if (entityTypeMap[entityType] === 'Review') {
            setModelId(null);
            setReviewId(getIdFromIri(entityIri));
        }
    };

    useEffect(() => {
        const loadDimension = async () => {
            const dimension = await dimensionResource.getIdFromSystemId('MRA_DIMENSION_SOURCE_GROUP');
            setDimension(dimension);
        };

        loadDimension();
    }, []);

    useEffect(() => {
        const loadModel = async () => {
            const model = await modelResource.getItem(modelId, true);
            const roleForModel = getUserRole(model);
            // check if a MRA is not already open for this model
            const withMRAOpen = model.mrasEntities.some((m) => m.isOpen);
            if (withMRAOpen) {
                setAskToClose(true);
            }

            setRole(roleForModel);
            setReady(true);
        };
        if (modelId) {
            loadModel();
        }
    }, [modelId]);

    useEffect(() => {
        // if both are null
        // ChooseMraSource should be displayed
        if (!modelId && !reviewId) {
            setReady(true);
        }
    }, [modelId, reviewId]);

    useEffect(() => {
        const loadReview = async () => {
            const review = await reviewResource.getItem(reviewId, true);

            // loadModels
            const models = await Promise.all(review.models.map((m) => modelResource.getItemFromResourcePath(m), true));
            const withMRAOpen = models.every((m) => m.mrasEntities.some((mr) => mr.isOpen));
            if (withMRAOpen) {
                setAskToClose(true);
            }
            const hasLoD2 = models.map((m) => getUserRole(m)).some((role) => role === 'LoD2');
            if (hasLoD2) {
                setRole('LoD2');
            } else {
                setRole('LoD1');
            }
            // filter out models with open MRAs in order to allow selection
            // only for models without opened MRAs
            setModels(models.filter((m) => !m.mrasEntities.some((mr) => mr.isOpen)).map((m) => m['@id']));
            setReady(true);
        };
        if (reviewId) {
            loadReview();
        }
    }, [reviewId]);

    if (!ready) {
        return (
            <Grid container justify="center" className="container">
                <div className="content" style={{ textAlign: 'center', marginTop: '6em', marginBottom: '6em' }}>
                    <LoadingIndicator />
                </div>
            </Grid>
        );
    }

    if (askToClose) {
        return (
            <Grid container justify="center" className="container">
                <div
                    className="content"
                    style={{ textAlign: 'center', marginTop: '3em', marginBottom: '3em', fontSize: '24px' }}
                >
                    <p>You can't create a MRA while another one is opened.</p>
                </div>
            </Grid>
        );
    }

    return (
        <React.Fragment>
            {modelId || reviewId || models ? (
                <InitMra 
                    modelId={modelId}
                    reviewId={reviewId}
                    models={models}
                    role={role}
                    dimension={dimension}
                    process={process}
                    mraSource={mraSource}
                    context={context}
                />
            ) : (
                <ChooseMraSource setSource={setSource} />
            )}
        </React.Fragment>
    );
};
Init.propTypes = {
    /**
     * Permet de déterminer si la création du MRA intervient dans un certain contexte,
     * exemple, après la fermeture d'une notice. Les contextes sont testés dans le listener back.
     */
    context: PropTypes.string,
}

/**
 * Formulaire de choix de l'entité pour laquelle on va créer le MRA : Review ou Model.
 *
 * @param {*} props
 */
const ChooseMraSource = (props) => {
    const { setSource } = props;

    const [choice, setChoice] = useState({});
    const [disabled, setDisabled] = useState(false);

    const fields = {
        entityType: {
            title: 'entity type',
            type: 'mapped',
            params: {
                mapping: entityTypeMap,
            },
        },
        review: {
            title: 'Review',
            type: 'entity',
            params: {
                resource: 'reviews',
                displayField: 'title',
            },
            issueButton: false,
            displayCondition: (entity, item) => entityTypeMap[entity.entityType] === 'Review',
        },
        model: {
            title: 'Model',
            type: 'model',
            params: {
                resource: 'models',
                instanceId: 'allModels',
                displayField: 'functionalID',
                endpoints: {
                    getAll: 'models/all-models',
                },
            },
            issueButton: false,
            displayCondition: (entity, item) => entityTypeMap[entity.entityType] === 'Model',
        },
    };

    useEffect(() => {
        setDisabled(
            choice.entityType === undefined ||
                choice.entityType === null ||
                (entityTypeMap[choice.entityType] === 'Model' && !choice.model) ||
                (entityTypeMap[choice.entityType] === 'Review' && !choice.review)
        );
    }, [choice]);

    const choose = () => {
        if (entityTypeMap[choice.entityType] === 'Model') {
            setSource(choice.entityType, choice.model);
        } else if (entityTypeMap[choice.entityType] === 'Review') {
            setSource(choice.entityType, choice.review);
        }
    };

    return (
        <Grid container justify="center" spacing={2} className="container resource-edit">
            <Grid item xs={6} style={styles.gridPaper}>
                <Paper style={styles.blockHeightStyle}>
                    <h1 className="background-linear-gradient">Choose Mra source entity</h1>
                    <EntityForm entity={choice} onUpdate={setChoice} fields={fields} />

                    <div className="container container-button">
                        <Button variant="contained" color="primary" disabled={disabled} onClick={choose}>
                            Continue
                        </Button>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
};

export const EditMra = (props) => {
    const { mraId } = props;
    let history = useHistory();

    return (
        <Grid container justify="center" className="container resource-edit">
            <Grid item xs={6} style={styles.gridPaper}>
                <Paper style={styles.blockHeightStyle}>
                    <p>There is already an opened MRA.</p>

                    <div className="container container-button">
                        <Button variant="contained" color="default" onClick={() => history.goBack()}>
                            Back
                        </Button>
                        <Link to={'/resource/mras/' + mraId + '/update'}>
                            <Button variant="contained" color="primary">
                                Update MRA
                            </Button>
                        </Link>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
};

const getRedirectPath = (id, role) => {
    if (role === 'LoD2') {
        return '/resource/mras/' + id + '/detail';
    }

    return '/resource/mras/' + id + '/update';
};

export const InitMra = (props) => {
    const { modelId, reviewId, models, role, dimension, process, mraSource, context } = props;
    const [mraOptions, setMraOptions] = useState({
        process: process,
        models: modelId ? ['/api/models/' + modelId] : models ?? [],
        mraSource: mraSource,
        dimension,
        review: reviewId ? '/api/reviews/' + reviewId : null,
        context: context,
    });

    useEffect(() => {
        if (dimension) {
            setMraOptions({
                ...mraOptions,
                dimension,
            });
        }
    }, [dimension]);

    const fields = {
        models: {
            title: 'Models',
            type: 'model',
            params: {
                resource: 'models',
                instanceId: 'allModels',
                displayField: 'functionalID',
                multi: true,
                noOption: false,
                additionalProperties: ['mrasEntities', 'reviews'],
                filters: (item) => {
                    if (!item) {
                        return false;
                    }

                    let pass = false;

                    if (modelId && String(item.id) === String(modelId)) {
                        pass = true;
                    }

                    if (modelId && item.mrasEntities) {
                        const openMras = item.mrasEntities.filter((m) => m.isOpen === true);
                        pass = openMras.length < 1;
                    }

                    if (reviewId && item.reviews && item.reviews.includes('/api/reviews/' + reviewId)) {
                        if (!item.mrasEntities) return true;

                        // Exclude model if there's an MRA currently opened
                        const openMras = item.mrasEntities.filter((m) => m.isOpen === true);
                        pass = openMras.length < 1;
                    }

                    return pass;
                },
                endpoints: {
                    getAll: 'models/all-models',
                },
            },
            helperText: 'The Mras will be created for these Models',
            required: true,
            requiredComputed: true,
        },
        mraSource: {
            title: 'Apply Scores from last MRA of',
            type: 'model',
            params: {
                resource: 'models',
                instanceId: 'allModels',
                displayField: 'functionalID',
                multi: false,
                noOption: false,
                additionalProperties: ['mrasEntities'],
                filters: (item, entity, key, context) => {
                    /** On n'affiche que les Models qui ont un MRA validé
                     * et qui portent sur la même dimension
                     * sinon on ne peut pas appliquer les scores
                     */
                    if (item.mrasEntities && item.mrasEntities.length > 0) {
                        return item.mrasEntities.some(
                            (mra) => mra.isComplete && mra.dimension === mraOptions.dimension
                        );
                    }

                    return false;
                },
                endpoints: {
                    getAll: 'models/all-models',
                },
            },
            required: false,
        },
    };

    const [disabled, setDisabled] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [redirectTo, setRedirectTo] = useState(false);

    const allRequiredFieldsFilled = () => {
        if (
            !mraOptions.dimension ||
            !mraOptions.models ||
            (mraOptions.models && Array.isArray(mraOptions.models) && mraOptions.models.length === 0)
        ) {
            return false;
        }
        return true;
    };

    const createAndContinue = async () => {
        if (!allRequiredFieldsFilled()) {
            Alert.show({
                message: 'Please fill all the required fields',
                type: 'warning'
            });
            return;
        }

        setUpdating(true);
        setDisabled(true);
        const showIncoherentModelsModal = await hasIncoherentModels(mraOptions.models);
        if (showIncoherentModelsModal) {
            openIncoherentModelsInformationModal({
                onConfirm: () => {}, // on se contente de fermer la modal d'information
                onCancel: () => {Modal.close()}, // si la création du MRA est effectuée dans une Modal on la ferme 
            });
            setUpdating(false);
            setDisabled(false);
            return;
        }
        const modelResource = new APIResource({
            id: 'models',
            name: 'Model',
        });

        const selectedModels = mraOptions.models.map(async (m) => {
            const model = await modelResource.getItemFromResourcePath(m);
            return model;
        });

        const models = await Promise.all(selectedModels);
        const process = getParamBySystemId(MRA_PROCESS.LOD2)['@id'];
        const mraOptionsConsolidated = { ...mraOptions, models, process };
        let req = await MRA.create(mraOptionsConsolidated);
        setUpdating(false);
        setDisabled(false);

        if (!req) {
            Alert.show({
                message: 'Erreur inattendue',
                type: 'warning'
            });
        }

        if (req.error) {
            Alert.show({
                message: req.message,
                type: 'warning'
            });
        } else {
            const firstMra = Array.isArray(req) ? req.shift() : req;
            setRedirectTo(firstMra.id);
        }
    };

    return (
        <>
            {
                // Redirect if Mras have been created
                // LOD2 is redirected to the detail page (for quick filling)
                // LOD1 to the update page
                redirectTo && (
                    <Redirect
                        to={{
                            pathname: getRedirectPath(redirectTo, role),
                            state: { mraOptions, skipClone: true },
                        }}
                    />
                )
            }

            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                spacing={2}
                className="container resource-edit"
            >
                <Grid item xs={6} style={styles.gridPaper}>
                    <Paper style={styles.blockHeightStyle}>
                        <h1 className="background-linear-gradient">MRA creation</h1>
                        <EntityForm
                            entity={mraOptions}
                            onUpdate={(mraOpts) => {
                                setMraOptions(mraOpts);
                            }}
                            fields={fields}
                        />

                        <ButtonBar>
                            <ActionButton loading={updating} disabled={disabled || updating} onClick={createAndContinue}>
                                Ok
                            </ActionButton>
                        </ButtonBar>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};
InitMra.propTypes = {
    /** @see Init */
    context: PropTypes.string,
}

const styles = {
    blockHeightStyle: {
        paddingBottom: 5,
        marginBottom: 35,
    },
    gridPaper: {
        marginTop: 15,
        marginBottom: 15,
        height: '100%',
    },
    listStyle: {
        marginBottom: 15,
    },
    requiredDocumentText: {
        marginLeft: 15,
        marginRight: 15,
        textAlign: 'center',
    },
};

export default Init;
