import React, { Component, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Alert from '../../../Services/Alert';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import {APIResource} from "../../../Services/APIResource/APIResource";
import {DisplayTextField} from "../../Display/DisplayTextField/DisplayTextField";
import { ButtonBar } from '../../Modal/ButtonBar';
import { ActionButton } from '../../Modal/ActionButton';
import { CircularProgress, Typography } from '@material-ui/core';
import {EntitySelect} from "../../Forms/EntitySelect/EntitySelect";
import ModalProvider from "../../../Services/ModalProvider";
import APIResourceStore from '../../../Store/APIResourceStore';

/** @type {Object.<string, import('../../../Services/APIResource/APIResource').APIResourceField} */
const fields = {
    mraDimension: {
        title: 'Mra dimension',
        type: 'entity',
        params: {
            resource: 'mra_dimensions',
            instanceId: 'finding_mra_dimensions',
            displayField: 'title',
            filters: {
                lang: 'en'
            },
        },
        required: true,
        requiredComputed: true,
    },
    mraSubDimension: {
        title: 'Mra Sub Dimension',
        type: 'entity',
        params: {
            resource: 'mra_subdimensions',
            instanceId: 'finding_mra_subdimensions',
            displayField: 'title',
            filters: {
                lang: 'en'
            },
        },
    },
};

class ModalFindingMra {
    constructor() {
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }
    open(params) {
        if (this.ref) {
            this.ref.open(params);
        }
        return this;
    }
    close() {
        if (this.ref) {
            this.ref.close();
        }
    }
}
const ModalFM = new ModalFindingMra();

class FindingMra extends Component {

    constructor(props) {
        super(props);
        this.openFindingMraModal = this.openFindingMraModal.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.resource = new APIResource({ id: this.props.field.params.resource, instanceId: 'finding_mra_' + this.props.field.params.resource });
        this.state = {
          value: '',
        };
    }

    componentDidMount() {
        this.updateValue(this.props.value);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.value !== prevProps.value) || (this.props.entity !== prevProps.entity)) {
            this.updateValue(this.props.value);
        }
    }

    genFindingMraForm(){
        return <FindingMraForm
            resource={this.resource}
            entity={this.props.entity}
            field={this.props.field}
            onSubmit={(entity) => {
                this.updateValue(entity[this.props.field.id]);
                this.props.onChange(this.props.entity[this.props.field.id]);
            }}
            inverseRelation={this.props.inverseRelation}
        />
    }

    openFindingMraModal(){
        return ModalFM.open({
            title: `Add a dimension`,
            content: this.genFindingMraForm(),
            contentStyle: { height: '425px' },
            modalStyle: {minHeight: "450px"},
            size: 'small'
        });
    };

    removeItem(i){
        this.props.entity[this.props.field.id].splice(i, 1);
        this.updateValue(this.props.entity[this.props.field.id]);
        this.props.onChange(this.props.entity[this.props.field.id]);
    }

    updateValue(value){
        if (Array.isArray(value) && value.length > 0 && typeof value[0] != 'object') {
            let promises = [];
            let values = [];
            value.forEach((val, index) => {
                const id = val && val.indexOf('/api') > -1 ? val.split('/').pop() : val;
                let promise = this.resource.getItemFromResourcePath(id).then((findingMraRelation) => {
                    if (!findingMraRelation) {
                        return;
                    }
                    let valLabel = findingMraRelation[this.props.field.params.displayField] ? findingMraRelation[this.props.field.params.displayField] : '-';
                    values.push(
                        <div key={index}>
                            {valLabel}
                            <Button
                                size="small"
                                style={{color: 'hsl(0,0%,80%)', marginLeft: 10, minWidth: 30}}
                                onClick={() => this.removeItem(index)}
                            >
                                <Icon className={"fa fa-times"}></Icon>
                            </Button>
                        </div>
                    );
                });
                promises.push(promise);
            });
            Promise.all(promises).then(() => {
                this.setState({value: <div>{values}</div>});
            });
        }else{
            this.setState({value: []});
        }
    }

    render() {
        return (
            <div
                className={
                    'select-component finding-mra-relation'
                }
            >
                {this.props.field.label ? (
                    <label>
                        {this.props.field.label}
                    </label>
                ) : null}
                <div className={'with-edit-button'}>
                    <DisplayTextField
                        fieldName={this.props.field.title}
                        value={this.state.value}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.openFindingMraModal}
                    >
                        <Icon className={"fa fa-plus"}></Icon>
                    </Button>
                    <ModalProvider
                        ref={(ref) => {
                            ModalFM.ref = ref;
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default observer(FindingMra);

class FindingMraForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mraDimension: null,
            mraSubDimension: null,
        };
        this.submit = this.submit.bind(this);
    }

    submit() {
        let entity = {};
        let self = this;

        if(self.state.mraDimension === null){
            Alert.show({ message: 'Please select a Mra dimension', type: 'error' });
            return;
        }

        entity.mraDimension = self.state.mraDimension;
        entity.mraSubDimension = self.state.mraSubDimension;
        entity.finding = self.props.entity['@id'];

        self.setState({ apiUpdateInProgress: true });
        self.props.resource.apiPost(entity).then((item) => {
            if(!self.props.entity.findingMraRelations){
                self.props.entity.findingMraRelations = [];
            }
            self.props.entity[self.props.field.id].push(item['@id']);
            self.props.onSubmit(self.props.entity);
            Alert.show({ message: `Dimension successfully added.`, type: "success" });
        })
        .finally(() => {
            ModalFM.close();
        });
    }

    render() {
        var subDimensionFilters = fields.mraSubDimension.params.filters;
        subDimensionFilters['mraDimension'] = this.state.mraDimension;
        return (
            <div className={"new-finding-mra-form"}>

                <EntitySelect
                    label={fields.mraDimension.title}
                    resourceId={fields.mraDimension.params.resource}
                    instanceId={fields.mraDimension.params.instanceId}
                    resourceLabel={fields.mraDimension.params.displayField}
                    value={this.state.mraDimension}
                    onChange={(val) => {
                        this.setState({mraDimension: val, mraSubDimension: null})
                    }}
                    clearable={!("required" in fields.mraDimension && fields.mraDimension.required === true)}
                    multi={fields.mraDimension.params.multi}
                    filters={fields.mraDimension.params.filters}
                    required={"required" in fields.mraDimension && fields.mraDimension.requiredComputed === true}
                    error={!!fields.mraDimension.errorHelperText}
                    className={fields.mraDimension.errorHelperText ? "field-error-control" : ""}
                    helperText={fields.mraDimension.errorHelperText}
                    endpoints={fields.mraDimension.params.endpoints}
                />

                {
                    this.state.mraDimension &&
                    <EntitySelect
                        label={fields.mraSubDimension.title}
                        resourceId={fields.mraSubDimension.params.resource}
                        instanceId={fields.mraSubDimension.params.instanceId}
                        resourceLabel={fields.mraSubDimension.params.displayField}
                        value={this.state.mraSubDimension}
                        onChange={(val) => {
                            this.setState({mraSubDimension: val})
                        }}
                        clearable={!("required" in fields.mraSubDimension && fields.mraSubDimension.required === true)}
                        multi={fields.mraSubDimension.params.multi}
                        filters={subDimensionFilters}
                        required={"required" in fields.mraSubDimension && fields.mraSubDimension.requiredComputed === true}
                        error={!!fields.mraSubDimension.errorHelperText}
                        className={fields.mraSubDimension.errorHelperText ? "field-error-control" : ""}
                        helperText={fields.mraSubDimension.errorHelperText}
                        endpoints={fields.mraSubDimension.params.endpoints}
                    />
                }


                <ButtonBar>
                    <ActionButton onClick={() => ModalFM.close()}>
                        Cancel
                    </ActionButton>
                    <ActionButton onClick={() => this.submit()} disabled={this.state.apiUpdateInProgress}>
                        {this.state.apiUpdateInProgress ?
                            <CircularProgress size={20} />
                            : 'Add' }
                    </ActionButton>
                </ButtonBar>
            </div>
        );
    }
}

export const EditFindingMraRelationsFromNotice = (props) => {
    const { entity, onChange, field, value } = props;

    const [findings, setFindings] = useState([]);

    const onChangeFromNotice = (finding, findings) => (findingMraRelations) => {
        finding[field.id] = findingMraRelations;
        return onChange([].concat(...findings.map((f) => f[field.id])));
        /** 
         * @todo il manque l'appel à apiPut de finding en cas de suppression d'une relation : 
         * comparer avec finding[field.id] avant et après si des relations ont disparu et
         * mettre à jour le finding en conséquence, car la dissociation d'avec la notice
         * n'aura pas d'effet puisque le FindingMraRelations est en lecture seule depuis les
         * Findings associés.
         */
    };

    useEffect(() => {
        const fetchFindings = async () => {
            const findings = await Promise.all(
                (entity?.findings || []).map((findingPath) =>
                    APIResourceStore.resources.findings.getItemFromResourcePath(findingPath, true)
                )
            );
            setFindings(findings);
        }
        fetchFindings();
    }, [value]);

    return findings.map((finding) => (
        <>
            <Typography component={'h2'}>{finding.toString}</Typography>
            <FindingMra
                key={`finding_mra_${finding.id}`}
                field={field}
                entity={finding}
                value={value.filter((findingMraRelation) =>
                    (finding?.findingMraRelations || []).includes(findingMraRelation)
                )}
                onChange={onChangeFromNotice(finding, findings)}
            />
        </>
    ));
}
