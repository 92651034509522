import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from 'react';
import {
    PARAMETER_TYPE_FINDING_NOTICE_SEVERITY,
    PARAMETER_TYPE_NOTICE_ENTITY,
    PARAMETER_TYPE_NOTICE_PERIMETER,
    PARAMETER_TYPE_NOTICE_SEGMENT
} from '../Admin/ParameterAdmin';
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import { LogicalDeleteButton } from "../Components/DeleteButton/LogicalDeleteButton";
import Banner from '../Components/Display/Banner/Banner';
import { ChangeLog } from '../Components/Display/ChangeLog/ChangeLog';
import {
    DOCUMENT_ACTION_ADD, DOCUMENT_ACTION_DELETE, DOCUMENT_ACTION_LIST, DOCUMENT_ACTION_SHOW,
    DocumentListAccordion,
    DocumentManager
} from '../Components/Display/DocumentManager/DocumentManager';
import { TableDisplay } from '../Components/Display/TableDisplay/TableDisplay';
import Thread from '../Components/Display/Thread/Thread';
import { EntityExport, EntityExportForm } from '../Components/Export/EntityExport';
import { NoticeActionForm } from "../Components/Forms/CloseModal/NoticeCloseModal";
import { Header } from "../Components/Header/Header";
import { EntityImportForm } from '../Components/Import/EntityImport';
import ChoiceModal from "../Components/Modal/ChoiceModal";
import { OpenModal } from "../Components/Modal/OpenModal";
import { openAutomaticScoringInformationModal } from '../Components/Mra/Display/AutomaticScoringInformationModal';
import { MarkAsDeliveredForm } from "../Components/Notice/MarkAsDeliveredForm";
import NoticeAfterClosingModal from "../Components/Notice/NoticeAfterClosingModal";
import NoticeReopenForm from '../Components/Notice/NoticeReopenForm';
import { PostponeRequestForm } from "../Components/Notice/PostponeRequestForm";
import ValidateFindingNotice from '../Components/Review/ValidateFindingNotice';
import { WorkflowStepProvider } from "../Components/WorkflowStep/WorkflowStep";
import { APIResource, CONTEXT_ADD, CONTEXT_DETAIL, CONTEXT_EDIT } from '../Services/APIResource/APIResource';
import DateProvider from "../Services/APIResource/FieldProviders/DateProvider";
import IntProvider from "../Services/APIResource/FieldProviders/IntProvider";
import ModelProvider from '../Services/APIResource/FieldProviders/ModelProvider';
import ParameterProvider from "../Services/APIResource/FieldProviders/ParameterProvider";
import TextareaProvider from '../Services/APIResource/FieldProviders/TextareaProvider';
import {
    noticeConfirmReceipt,
    noticeMissingFieldsAndFinding,
    // noticeMissingFinding,
    submitToNoticeOwnerToReviewAction
} from "../Services/Actions/NoticeActions";
import Alert from "../Services/Alert";
import { BulkDelete } from '../Services/BulkActions/BulkDelete/BulkDelete';
import { BulkEdit } from "../Services/BulkActions/BulkEdit/BulkEdit";
import { CloseNotice } from "../Services/BulkActions/BulkNotice/CloseNotice";
import { RemindNotice } from "../Services/BulkActions/BulkNotice/RemindNotice";
import DateFormatter from '../Services/DateFormatter';
import { GetEnvironmentUriParameter } from "../Services/Environment";
import Modal from "../Services/Modal";
import ModalComplementary from '../Services/ModalComplementary';
import Navigation from "../Services/Navigation";
import User, { ROLE as Role } from "../Services/User/User";
import { getIdFromIri } from "../Services/utils";
import APIResourceStore from "../Store/APIResourceStore";
import ParameterStore, {
    USER_SCOPE_ALL,
    amIAuthor,
    getParamBySystemId,
    userHasInChargeRights,
    userHasIssuerRights,
    userHasNoticeOwnershipRights,
    userHasRights,
    userHasRoleADMIN,
    userHasRoleIG,
    userHasRoleMRM,
    userHasSpecificRole,
    userIsVal
} from '../Store/ParameterStore';
import { FINDING_STATUS_CLOSED, FINDING_STATUS_DELETED, FINDING_STATUS_DISMISSED, FINDING_STATUS_DRAFT, isValOfFinding } from "./FindingAdmin";
import { PARAMETER_TYPE_NOTICE_BREACH, PARAMETER_TYPE_NOTICE_STATUS } from "./ParameterAdmin";
import {
    DISPLAY,
    DISPLAY_ADD,
    DISPLAY_LIST,
    EDIT,
    HIDDEN,
    REQUIRED,
    fieldHighlightedParam,
    isDisplayed,
    isEditable,
    isRequired
} from './common';
import { UpcomingValidationForClosureModal } from "../Components/Notice/UpcomingValidationForClosureModal";
/** Edit FindingMraRelations désactivé pour l'instant : */
//import { EditFindingMraRelationsFromNotice } from '../Components/Finding/Add/FindingMra';

const getModel = (modelPath) => {
    if(modelPath){
        return APIResourceStore.resources.models.getObservableItemByPath(modelPath);
    }
}

/** @see App\Traits\OriginTrait dans le back */
const ORIGIN_REVIEW = 'review';

/**
 * Local function to know if the user is VAL of Notice, Review or Model.
 *
 * @param {Object} notice Notice entity
 * @returns {bool}
 * @see: https://app.asana.com/0/1134038211401766/1201201533578189/f
 */
const isValOfNotice = (notice) => {
    // Pour gagner du temps on vérifie d'abord les champs standards avant de chercher dans les teams.
    if (userHasInChargeRights(User.getId(), notice)) return true;
    if (userHasIssuerRights(User.getId(), notice)) return true;

    return userIsVal(User, notice);
}

export const isMemberOfNoticeOwnerTeams = (notice) => {
    return userHasRights(User, notice, 'noticeOwnerTeams', USER_SCOPE_ALL);
}

/**
 * Mapping de l'index du tableau des droits en fonction du rôle cherché.
 * @see FIELDS_BY_STATUS
 */
const ROLE_INDEX_MAPPING = {
    val: 0,
    owner: 1,
    other: 2,
}

/**
 * on positionne dans l'ordre les droits pour : VAL,Owner,others
 * Chaque droit se définit en fonction d'un ensemble de permissions @see common.js
 * Par exemple : EDIT + REQUIRED , qui donne un number qui est la somme des permissions autorisées.
 * (un peu comme en POSIX pour les droits des fichiers : 644 => r+w,r,r)
 *
 * NB: attention, les champs "HIDDEN" sont envoyés 'NULL' au back, sauf s'ils ont la propriété
 *     doNotResetValueWhenNotDisplayed.
 */
const FIELDS_BY_STATUS = {
    id: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    initialId: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [EDIT, DISPLAY, DISPLAY],
        validation: [EDIT, DISPLAY, DISPLAY],
        closed: [EDIT, DISPLAY, DISPLAY],
    },
    title: {
        draft: [EDIT + REQUIRED, DISPLAY + REQUIRED, DISPLAY + REQUIRED],
        open: [EDIT + REQUIRED, DISPLAY + REQUIRED, DISPLAY + REQUIRED],
        validation: [EDIT + REQUIRED, DISPLAY + REQUIRED, DISPLAY + REQUIRED],
        closed: [DISPLAY + REQUIRED, DISPLAY + REQUIRED, DISPLAY + REQUIRED],
    },
    noticeSeverity: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    review: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    models: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    noticeIssuer: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    noticeIssuerTeams: {
        draft: [DISPLAY - DISPLAY_ADD, DISPLAY - DISPLAY_ADD, DISPLAY - DISPLAY_ADD],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    noticeValidators: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [EDIT + REQUIRED, DISPLAY + REQUIRED, DISPLAY + REQUIRED],
        validation: [EDIT + REQUIRED, DISPLAY + REQUIRED, DISPLAY + REQUIRED],
        closed: [DISPLAY + REQUIRED, DISPLAY + REQUIRED, DISPLAY + REQUIRED],
    },
    validatorsInChargeOfTeams: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    noticeOwners: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [EDIT + REQUIRED, DISPLAY + REQUIRED, DISPLAY + REQUIRED],
        validation: [EDIT + REQUIRED, DISPLAY + REQUIRED, DISPLAY + REQUIRED],
        closed: [DISPLAY + REQUIRED, DISPLAY + REQUIRED, DISPLAY + REQUIRED],
    },
    noticeOwnerTeams: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [EDIT, DISPLAY, DISPLAY],
        validation: [EDIT, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    noticeDescription: {
        draft: [EDIT + REQUIRED, DISPLAY + REQUIRED, DISPLAY + REQUIRED],
        open: [EDIT + REQUIRED, DISPLAY + REQUIRED, DISPLAY + REQUIRED],
        validation: [EDIT + REQUIRED, DISPLAY + REQUIRED, DISPLAY + REQUIRED],
        closed: [DISPLAY + REQUIRED, DISPLAY + REQUIRED, DISPLAY + REQUIRED],
    },
    actionPlan: {
        draft: [EDIT, EDIT, DISPLAY],
        open: [EDIT + REQUIRED, EDIT + REQUIRED, DISPLAY + REQUIRED],
        validation: [EDIT + REQUIRED, EDIT + REQUIRED, DISPLAY + REQUIRED],
        closed: [DISPLAY + REQUIRED, DISPLAY + REQUIRED, DISPLAY + REQUIRED],
    },
    expectedDeliverables: {
        draft: [EDIT, EDIT, DISPLAY],
        open: [EDIT, EDIT, DISPLAY],
        validation: [EDIT, EDIT, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    perimeter: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [EDIT, DISPLAY, DISPLAY],
        validation: [EDIT, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    segment: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [EDIT, DISPLAY, DISPLAY],
        validation: [EDIT, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    entity: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [EDIT, DISPLAY, DISPLAY],
        validation: [EDIT, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    findingMraRelations: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [EDIT, DISPLAY, DISPLAY],
    },
    status: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    hasUpcomingValidationForClosure: {
        draft: [HIDDEN, HIDDEN, HIDDEN],
        open: [HIDDEN, HIDDEN, HIDDEN],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    upcomingReview: {
        draft: [HIDDEN, HIDDEN, HIDDEN],
        open: [HIDDEN, HIDDEN, HIDDEN],
        validation: [EDIT, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    breach: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    delay: {
        draft: [HIDDEN, HIDDEN, HIDDEN],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [HIDDEN, HIDDEN, HIDDEN],
        closed: [HIDDEN, HIDDEN, HIDDEN],
    },
    issuanceDate: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    statusDraftToProgressDate: {
      draft: [DISPLAY, DISPLAY, DISPLAY],
      open: [DISPLAY, DISPLAY, DISPLAY],
      validation: [DISPLAY, DISPLAY, DISPLAY],
      closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    deadline: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    revisedDeadline: {
        draft: [DISPLAY, DISPLAY, HIDDEN],
        open: [DISPLAY, DISPLAY, HIDDEN],
        validation: [DISPLAY, DISPLAY, HIDDEN],
        closed: [DISPLAY, DISPLAY, HIDDEN],
    },
    revisedDeadlineJustification : {
        draft: [DISPLAY, DISPLAY, HIDDEN],
        open: [DISPLAY, DISPLAY, HIDDEN],
        validation: [DISPLAY, DISPLAY, HIDDEN],
        closed: [DISPLAY, DISPLAY, HIDDEN],
    },
    deliveryDate: {
        draft: [HIDDEN, HIDDEN, HIDDEN],
        open: [HIDDEN, HIDDEN, HIDDEN],
        validation: [EDIT, DISPLAY, DISPLAY],
        closed: [EDIT, DISPLAY, DISPLAY],
    },
    deliveryComment: {
        draft: [HIDDEN, HIDDEN, HIDDEN],
        open: [HIDDEN, HIDDEN, HIDDEN],
        validation: [EDIT, DISPLAY, DISPLAY],
        closed: [EDIT, DISPLAY, DISPLAY],
    },
    effectiveClosingDate: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    progress: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [EDIT, DISPLAY, DISPLAY],
        validation: [EDIT, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    comments: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [EDIT, DISPLAY, DISPLAY],
        validation: [EDIT, DISPLAY, DISPLAY],
        closed: [EDIT, DISPLAY, DISPLAY],
    },
    closingComment: {
        draft: [HIDDEN, HIDDEN, HIDDEN],
        open: [HIDDEN, HIDDEN, HIDDEN],
        validation: [HIDDEN, HIDDEN, HIDDEN],
        closed: [EDIT, DISPLAY, DISPLAY],
    },
    dependsNotices: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [EDIT, DISPLAY, DISPLAY],
        validation: [EDIT, DISPLAY, DISPLAY],
        closed: [EDIT, DISPLAY, DISPLAY],
    },
    prerequisiteForNotices: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [EDIT, DISPLAY, DISPLAY],
        validation: [EDIT, DISPLAY, DISPLAY],
        closed: [EDIT, DISPLAY, DISPLAY],
    },
    documentsEntities: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    findingsEntities: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    mitigationActionsEntities: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
     changeLogsEntities: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    changeLogComment: {
        draft: [HIDDEN, HIDDEN, HIDDEN],
        open: [HIDDEN, HIDDEN, HIDDEN],
        validation: [HIDDEN, HIDDEN, HIDDEN],
        closed: [HIDDEN, HIDDEN, HIDDEN],
    },
    messages: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        validation: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    validatorComments: {
        draft: [EDIT, HIDDEN, HIDDEN],
        open: [EDIT, HIDDEN, HIDDEN],
        validation: [EDIT, HIDDEN, HIDDEN],
        closed: [EDIT, HIDDEN, HIDDEN],
    },
    blockingNotice: {
        draft: [EDIT, EDIT, EDIT],
        open: [EDIT, EDIT, EDIT],
        validation: [EDIT, EDIT, EDIT],
        closed: [EDIT, EDIT, EDIT],
    },
    /**
     * @todo displayConditions n'utilise pas displayList donc DISPLAY_LIST n'est pas utilisé.
     * On pourrait soit ajouter un listConditions, soit utiliser `displayList = () =>` pour tester
     * si le champ a les droits et renvoyer le composant adéquat.
     */
    readOwnershipNotification: {
        draft: [DISPLAY_LIST, DISPLAY_LIST, DISPLAY_LIST],
        open: [DISPLAY_LIST, DISPLAY_LIST, DISPLAY_LIST],
        validation: [DISPLAY_LIST, DISPLAY_LIST, DISPLAY_LIST],
        closed: [DISPLAY_LIST, DISPLAY_LIST, DISPLAY_LIST],
    }
}

/** Mapping entre le statut de l'entité et le nom dans FIELDS_BY_STATUS */
const getStatusKey = (status) => {
    if (status === ParameterStore('NOTICE_STATUS_DRAFT')) return "draft";
    if (status === ParameterStore('NOTICE_STATUS_IN_PROGRESS')) return "open";
    if (status === ParameterStore('NOTICE_STATUS_WAITING_FOR_VALIDATION'))  return "validation";
    if (status === ParameterStore('NOTICE_STATUS_CLOSED')) return "closed";
    if (status === ParameterStore('NOTICE_STATUS_DISMISSED')) return "closed";
    return "";
}

const header = (entity) => {
    let header = [];

    header.push(<WorkflowStepProvider
        key={"notice_step"}
        entity={entity}
        activeStep={(entity) => {
            if(entity.status === ParameterStore('NOTICE_STATUS_DRAFT')){
                return 0
            }else if(entity.status === ParameterStore('NOTICE_STATUS_IN_PROGRESS')){
                return 1
            }else if(entity.status === ParameterStore('NOTICE_STATUS_WAITING_FOR_VALIDATION')){
                return 2
            }else if(
                entity.status === ParameterStore('NOTICE_STATUS_CLOSED')
                || entity.status === ParameterStore('NOTICE_STATUS_DISMISSED')
            ){
                return 3
            }
            return null;
        }}
        steps={[
            {
                label: "Draft",
                key: 0
            },
            {
                label: "Open",
                key: 1
            },
            {
                label: "Under review",
                key: 2
            },
            {
                label: "Closed / Cancelled",
                key: 3
            }
        ]}
    />);

    let message = undefined;
    if (entity.status === ParameterStore('NOTICE_STATUS_DRAFT')) {
        message = isValOfNotice(entity)
            ? !entity.hasOriginReview
                ? 'The status of your notice is Draft and you can start to edit it. ' +
                  'Please use the "Confirm the notice and associated finding(s)" button below in order to send the notice to its recipient.'
                : 'The status of your notice is Draft and you can start to edit it.' +
                  'You can click on the "Confirm the notice and associated finding(s)" button below in order to send the notice to its recipient.' +
                    'Otherwise, the notice will be confirmed when the review is closed.'
            : message;
    } else if (entity.status === ParameterStore('NOTICE_STATUS_IN_PROGRESS')) {
        if (isValOfNotice(entity)) {
            message =
                !entity.readOwnershipNotification
                    ? 'This notice is open, waiting for confirmation of receipt. ' +
                      'You will be notified when the Notice Owner confirms receipt.'
                    : 'This notice is open waiting for an answer from the Notice Owner. ' +
                      'You will be notified when it is submitted for closure.';
        } else if (userHasNoticeOwnershipRights(User.getId(), entity)) {
            message = !entity.readOwnershipNotification
                ? 'A new notice has been submitted to you. Please confirm receipt by clicking on the green button.'
                : 'This notice is open, waiting for your delivery. ' +
                'Please submit your answer to Validation by clicking on the blue button.';
        }
    } else if (entity.status === ParameterStore('NOTICE_STATUS_WAITING_FOR_VALIDATION')) {
        if (isValOfNotice(entity)) {
            message =
                'The notice is waiting for your Validation, please review the deliverables from the Notice Owner. ' +
                'Before closing this notice, please indicate the evidence folder path in the documents tab and upload the committee minutes if applicable.';
        } else if (userHasNoticeOwnershipRights(User.getId(), entity)) {
            message =
                'This notice is under review. You will be notified if VAL closes this Notice or requests more information.';
        }
    }

    message && header.push(<Header key="message" text={message} />);

    return header;
}

/**
 * Détermine si le champ est requis à partir du tableau FIELDS_BY_STATUS.
 *
 * @param {*} entity
 * @param {string} fieldId
 * @returns {boolean}
 */
const requiredByStatus = (entity, fieldId) => {
    // Aucun champ requis pour MRM
    if (userHasRoleMRM()) return false;

    // Par défaut le champ n'est pas requis, et on reproduit la structure des droits avec le .map , avec une permission non "required", genre DISPLAY.
    const rights = FIELDS_BY_STATUS[fieldId]?.[getStatusKey(entity.status)] || Object.keys(ROLE_INDEX_MAPPING).map(_role => DISPLAY);
    let required = false;

    if (isValOfNotice(entity)){
        required = isRequired(rights[ROLE_INDEX_MAPPING.val]);
    } else if (userHasNoticeOwnershipRights(User.getId(), entity)) {
        required = isRequired(rights[ROLE_INDEX_MAPPING.owner]);
    } else {
        required = isRequired(rights[ROLE_INDEX_MAPPING.other]);
    }

    return required;
};

/**
 * Affiche l'élément en fonction du contexte, du statut et du rôle,
 * définis dans la const FIELDS_BY_STATUS.
 *
 * @returns {boolean}
 */
const displayConditions = (entity, parentState, key, context_, fieldId) => {
    /**
     * @todo pour l'instant displayConditions ne reçoit pas le contexte "add"
     * donc on bidouille ici pour le récupérer et s'en servir.
     * @fixme il faudra corriger ça !!!
     */
    const context = entity.id ? context_ : "add";

    if (userHasRoleMRM())
        return true;

    // Par défaut on cache, si les champs n'existent pas, et on reproduit la structure des droits avec le .map
    const rights = FIELDS_BY_STATUS[fieldId]?.[getStatusKey(entity.status)] || Object.keys(ROLE_INDEX_MAPPING).map(_role => HIDDEN);

    if (isValOfNotice(entity))
        return isDisplayed(rights[ROLE_INDEX_MAPPING.val], context);

    if (userHasNoticeOwnershipRights(User.getId(), entity))
        return isDisplayed(rights[ROLE_INDEX_MAPPING.owner], context);

    return isDisplayed(rights[ROLE_INDEX_MAPPING.other], context);
}

/**
 * Indique s'il faut afficher le composant d'édition ou de display en fonction du contexte, du statut et du rôle,
 * définis dans la const FIELDS_BY_STATUS.
 *
 * @returns {boolean}
 */
const editConditions = (field, value, entity) => {
    // Par défaut on affiche le composant DISPLAY, si les champs n'existent pas, et on reproduit la structure des droits avec le .map
    const rights = FIELDS_BY_STATUS[field.id]?.[getStatusKey(entity.status)] || Object.keys(ROLE_INDEX_MAPPING).map(_role => DISPLAY);
    let editable = false;

    if (userHasRoleMRM()) {
        editable = true;
    } else if (isValOfNotice(entity)){
        editable = isEditable(rights[ROLE_INDEX_MAPPING.val]);
    } else if (userHasNoticeOwnershipRights(User.getId(), entity)) {
        editable = isEditable(rights[ROLE_INDEX_MAPPING.owner]);
    } else {
        editable = isEditable(rights[ROLE_INDEX_MAPPING.other]);
    }

    return editable;
}

/**
 * Paramètres par défaut pour chaque champ de APIResource.
 *
 * Permet d'automatiser les tests sur required, displayed ou editable.
 */
const fieldDefaultParams = {
    ...fieldHighlightedParam,
    displayConditions,
    editConditions,
    required: requiredByStatus,
    /**
     * Attention, comme on filtre beaucoup les champs à afficher, il faut quand même
     * forcer leur envoi, sinon un champ non affiché est supprimé en back.
     */
    //doNotResetValueWhenNotDisplayed: true,
}

const additionalLinkButton = (entity, _resource, _resourceDetailComponent) => {
    let additionalActionButtons = [];
    const reviews = entity.reviews;
    if(reviews?.length){
        const reviewsEntities = reviews.map(review => APIResourceStore.resources.reviews.getObservableItemByPath(review));
        const links = reviewsEntities.map((review) =>({
            link: `/resource/reviews/${review.id}/detail?tab=Notices`,
            title: `Review ${review.reviewId}`,
        }));
        additionalActionButtons.push({
            links,
            tooltip: 'Associated reviews',
            icon: 'chevron-left',
            doNotDisplayInModal: true
        });
    }

    return additionalActionButtons;
}
const additionalActionButtons = (entity, resource, resourceDetailComponent) => {
    let additionalActionButtons = [];

    if(
        entity.deadline
        && (
            userHasNoticeOwnershipRights(User.getId(), entity)
            || isValOfNotice(entity)
            || userHasRoleMRM()
        ) && entity.status !== ParameterStore('NOTICE_STATUS_CLOSED')
        && entity.status !== ParameterStore('NOTICE_STATUS_DRAFT')
    ){
        additionalActionButtons.push({
            onClick: (_button) =>
                ModalComplementary.open({ 
                    title: 'Postponement request',
                    content: (
                        <PostponeRequestForm
                            entity={entity}
                            resource={resource}
                            userBusinessRole={
                                /** @see BusinessRoleVoter en back */
                                userHasNoticeOwnershipRights(User.getId(), entity)
                                    ? 'IS_OWNER'
                                    : (isValOfNotice(entity) || userHasRoleMRM())
                                    ? 'IS_VALIDATOR'
                                    : null
                            }
                            parentResourceComponent={resourceDetailComponent}
                        />
                    ),
                    size: "medium",
                }),
            tooltip: 'Postponement',
            icon: 'calendar-times',
        });
    }
    if(
        entity.revisedDeadline
        && entity.revisedDeadlineJustification
        && entity.revisedDeadlineWaitingForApproval
        && (
            (
                (isValOfNotice(entity) || userHasRoleMRM())
                && entity.revisedDeadlineAuthorRole === 'IS_OWNER'
            )
            || (
                userHasNoticeOwnershipRights(User.getId(), entity)
                && entity.revisedDeadlineAuthorRole === 'IS_VALIDATOR'
            )
        )
    ){
        ModalComplementary.open({
            title: "Confirm the new proposed deadline",
            content: (
                <PostponeRequestForm
                    entity={entity}
                    resource={resource}
                    requestApproval={true}
                    userBusinessRole={
                        /** @see BusinessRoleVoter en back */
                        userHasNoticeOwnershipRights(User.getId(), entity)
                            ? 'IS_OWNER'
                            : (isValOfNotice(entity) || userHasRoleMRM())
                            ? 'IS_VALIDATOR'
                            : null
                    }
                />
            ),
            size: "medium",
        })
    }

    if (
        entity.status === ParameterStore('NOTICE_STATUS_IN_PROGRESS')
        && (
            isValOfNotice(entity)
            || userHasInChargeRights(User.getId(), entity)
        )
    ) {
        additionalActionButtons.push({
            onClick: (button) => submitToNoticeOwnerToReviewAction(button.entity),
            tooltip: "Remind the Notice Owner(s)",
            icon: "bell",
            className: "warning",
        });
    }

    if (
        entity.status === ParameterStore('NOTICE_STATUS_IN_PROGRESS')
        && (
            userHasNoticeOwnershipRights(User.getId(), entity)
            || isMemberOfNoticeOwnerTeams(entity)
            || isValOfNotice(entity)
            || userHasRoleMRM()
        )
    ) {
        additionalActionButtons.push({
            tooltip: 'Mark as delivered',
            linkParam: 'mad',
            icon: 'file-import',
            className: 'info',
            onClick: () => {
                Modal.open({
                    title: "Delivery",
                    content: (
                        <MarkAsDeliveredForm resource={resource} entity={entity} resourceComponent={resourceDetailComponent}/>
                    ),
                })
            },
        });
    }

    if (
        entity.status === ParameterStore('NOTICE_STATUS_DRAFT')
        && (isValOfNotice(entity) || userHasRoleMRM())
    ){
        additionalActionButtons.push({
            label: 'Confirm the notice and associated finding(s)',
            tooltip: 'Confirm the notice and associated finding(s)',
            className: 'agree',
            icon: 'arrow-up',
            onClick: () => {
                if(entity.findingsEntities.length === 0){
                    if(entity.reviews?.length){
                        Navigation.router.history.push(
                            `/resource/reviews/${getIdFromIri(entity.reviews[0])}/detail?tab=Notices`
                        );
                        Alert.show({
                            message: 'Issues : the notice should be associated to at least one finding',
                            type: 'warning',
                        });
                    }
                }else{
                    Modal.open({
                        title: 'Confirm the notice and associated finding(s)',
                        content: (
                            <ValidateFindingNotice
                                findings={entity.findingsEntities}
                                notices={[entity]}
                                resource={APIResourceStore.resources.models}
                                resourceNotice={resource}
                                resourceFinding={APIResourceStore.resources.notices_findings}
                                entity={APIResourceStore.resources?.models?.getObservableItemByPath(entity?.models[0])}
                                fromModel={true}
                                fromNotice={true}
                                confirmationMessage={'Your notice and associated finding(s) have been confirmed. The Notice Owner(s) have been notified.'}
                                resourceComponent={resourceDetailComponent}
                            />
                        ),
                    });
                }
            },
        });
    }

    if (
        entity.status === ParameterStore('NOTICE_STATUS_CLOSED')
        && (isValOfNotice(entity) || userHasRoleMRM())
    ){
        additionalActionButtons.push({
            label: 'Reopen the notice',
            tooltip: 'Reopen the notice',
            icon: 'undo',
            onClick: () => {
                Modal.open({
                    title: 'Reopen the notice',
                    content: (
                        <NoticeReopenForm
                            notice={entity}
                            resource={resource}
                            resourceDetailComponent={resourceDetailComponent}
                        />
                    ),
                });
            },
        });
    }

    if (
        entity.status === ParameterStore('NOTICE_STATUS_IN_PROGRESS')
        && userHasNoticeOwnershipRights(User.getId(), entity)
        && !entity.readOwnershipNotification
    ) {
        additionalActionButtons.push({
            link: '/resource/' + resource.instanceId + '/' + entity.id + '/edit?cr=true', //Redirect to Edit view and trigger the same action button
            tooltip: 'Confirm receipt',
            icon: 'check',
            className: 'agree',
        });
    }

    if (
        isValOfNotice(entity)
        || userHasInChargeRights(User.getId(), entity)
        || userHasRoleMRM()
    ) {
        if (
            entity.status === ParameterStore('NOTICE_STATUS_WAITING_FOR_VALIDATION')
            || entity.status === ParameterStore('NOTICE_STATUS_IN_PROGRESS')
        ){

            let callbackAfterCloseCancel = (notice, resourceDetailComponent) => {
                resourceDetailComponent.entity = notice;
                resourceDetailComponent.forceUpdate();
                Navigation.router.history.push('/blank');
                setTimeout(() => {
                    Navigation.router.history.push(
                        `/resource/notices/${notice.id}/detail?tab=Findings`
                    );
                    resourceDetailComponent.setState({currentTab: resource.getTabId(CONTEXT_DETAIL, 'Findings')})
                },10)

                if(entity.status ===  ParameterStore('NOTICE_STATUS_CLOSED')){
                    Modal.open({
                        title: `The notice ${notice.id} has been closed.`,
                        content: <NoticeAfterClosingModal
                            notice={notice}
                            resource={resource}
                            resourceComponent={resourceDetailComponent}
                        />
                    });
                }
            }


            const openCloseNoticeModal = () => {
console.log('click on openCloseNoticeModal');
                Modal.open({
                    title: "Notice closure",
                    content: <NoticeActionForm
                        entityType='notice'
                        entity={entity}
                        resource={resource}
                        resourceDetailComponent={resourceDetailComponent}
                        action={'close'}
                        actionTitle={'Close'}
                        requiredComment={true}
                        callback={callbackAfterCloseCancel}
                    />,
                });
            }
            const openCancelNoticeModal = (button) => Modal.open({
                title: "Cancel",
                content: <NoticeActionForm
                    entityType='notice'
                    entity={button.entity}
                    resource={resource}
                    resourceDetailComponent={
                        resourceDetailComponent
                    }
                    action={'dismiss'}
                    actionTitle={'Cancel this notice'}
                    successMessage={'The notice has been cancelled. Following the cancellation of the notice, the related findings have been cancelled.'}
                    requiredComment={true}
                    callback={callbackAfterCloseCancel}
                />,
            })

            const noticeHasMraRelations = (entity?.findingMraRelations || []).length > 0;
            additionalActionButtons.push({
                onClick: (_button) => noticeHasMraRelations ? openAutomaticScoringInformationModal(openCloseNoticeModal) : openCloseNoticeModal(),
                tooltip: "Close this notice",
                icon: "check-circle",
                className: "agree",
            });
            additionalActionButtons.push({
                onClick: (button) => noticeHasMraRelations ? openAutomaticScoringInformationModal(() => openCancelNoticeModal(button)) : openCancelNoticeModal(button),
                tooltip: "Cancel this notice",
                icon: "ban",
                className: "retire trash",
            });
        }
    }

    if (
        (
            isValOfNotice(entity)
            || userHasRoleMRM()
        )
        && entity.status === ParameterStore('NOTICE_STATUS_WAITING_FOR_VALIDATION')
    ){
        additionalActionButtons.push({
            onClick: (button) =>
                Modal.open({
                    title: "Request more information",
                    content: <NoticeActionForm
                        entityType='notice'
                        entity={button.entity}
                        resource={resource}
                        resourceDetailComponent={
                            resourceDetailComponent
                        }
                        action={'moreInfo'}
                        actionTitle={'Request more information'}
                        successMessage={'The Notice Owner(s) received your request for further information.'}
                        requiredComment={true}
                        callback={(notice, resourceDetailComponent) => {
                            resourceDetailComponent.entity = notice;
                            resourceDetailComponent.forceUpdate();}
                        }
                    />,
                }),
            tooltip: "Request more information",
            icon: "backward",
        });

        additionalActionButtons.push({
            onClick: (button) =>
                Modal.open({
                    title: "Upcoming validation for closure",
                    content: <UpcomingValidationForClosureModal
                        notice={button.entity} 
                        resourceDetailComponent={resourceDetailComponent}
                    />,
                    size: 'medium',
                }),
            tooltip: "Link to upcoming validation for closure",
            icon: "search",
        });
    }

    return additionalActionButtons;
};

/**
 * @returns {Object.<string, import('../Services/APIResource/APIResource').APIResourceField>}
 */
export const NOTICE_FIELDS = (_resource) => ({
    id: {
        title: 'ID',
        helperText: 'Notice ID assigned by the tool',
        ...fieldDefaultParams,
        params: {
            filterMulti: true,
        },
    },
    initialId: {
        title: 'Notice Initial ID',
        helperText: 'Notice ID before MrM\'s tool implementation',
        ...fieldDefaultParams,
        params: {
            filterMulti: true,
        },
        disableTypeFormatValidation:true
    },
    title: {
        title: 'Title',
        type: 'text',
        maxLength:255,
        helperText: 'Please give a short and explicit title to the notice',
        ...fieldDefaultParams
    },
    reviews: {
        title: 'Reviews',
        type: 'entity',
        params: {
            resource: 'reviews',
            instanceId: 'notice_reviews',
            displayField: 'toString',
            links: true,
            multi: true,
            linkPath: (entity) => '/resource/reviews/' + entity.id + '/detail',
            endpoints: {
                getAll: 'string/entities/review',
            },
            searchedFields: ['id', '@id', 'reviewId'],
        },
        bulk: true,
        helperText: 'ID of the review(s) that led to the notice',
        ...fieldDefaultParams,
        doNotResetValueWhenNotDisplayed: true,
    },
    noticeSeverity: {
        title: 'Notice severity',
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_FINDING_NOTICE_SEVERITY,
            multi: false,
        },
        helperText: 'The notice severity is automatically defined by the highest severity of the attached open findings',
        /** @todo a priori inutile avec le nouveau editConditions, sauf pour empêcher MRM de modifier */
        edit: (field, value, onChange, entity, _routeParams) => {
            return ParameterProvider.getDisplay(field, value, entity, {});
        },
        ...fieldDefaultParams,
    },
    noticeIssuer: {
        title: 'Notice issuer',
        type: 'userAsync',
        params: {
            resource: 'users',
            instanceId: 'users_all',
            displayField: 'toString',
            editDisplayField: 'fullNameWithTeam',
            links: true,
            linkPath: (entity) => `/resource/users/${entity.id}/detail`,
            endpoints: {
                getAll: 'users/all-users/all',
            },
        },
        bulk: true,
        helperText: 'Name of the validator that initially emitted the notice',
        ...fieldDefaultParams,
    },
    noticeIssuerTeams: {
        title: 'Notice issuer teams',
        type: 'entityAsync',
        params: {
            resource: 'scopes',
            instanceId: 'scopes_nit',
            displayField: 'title',
            links: true,
            linkPath: (entity) => `/resource/scopes/${entity.id}/detail`,
            multi: true,
            orExistsMultiFilterNotAllowed: true,
            endpoints: {
                getAll: 'scopes/all-scopes/nit',
            }
        },
        helperText: 'Team of the notice issuer (based on group repository)',
        ...fieldDefaultParams,
    },
    validatorsInChargeOfTeams: {
        title: 'Validators in charge of the follow-up teams',
        type: 'entity',
        helperText: 'Team(s) of the validator(s) in charge of the follow-up (based on group repository)',
        params: {
            resource: 'scopes',
            instanceId: 'scopes_nvt',
            displayField: 'title',
            links: true,
            linkPath: (entity) => `/resource/scopes/${entity.id}/detail`,
            multi: true,
            orExistsMultiFilterNotAllowed: true,
            endpoints: {
                getAll: 'scopes/all-scopes/nvt',
            }
        },
        ...fieldDefaultParams,
        editConditions: () => false, // Pour empêcher la modification à MRM ou ADMIN malgré tout
    },
    noticeValidators: {
        title: 'Validators in charge of the follow-up',
        type: 'userAsync',
        params: {
            resource: 'users',
            instanceId: 'users_all',
            displayField: 'toString',
            editDisplayField: 'fullNameWithTeam',
            links: true,
            linkPath: (entity) => `/resource/users/${entity.id}/detail`,
            multi: true,
            endpoints: {
                getAll: 'users/all-users/all',
            },
        },
        bulk: true,
        helperText: 'Name of the validator(s) currently in charge of the follow-up of the remediation work',
        ...fieldDefaultParams,
    },
    noticeOwners: {
        title: 'Notice owners',
        type: 'userAsync',
        params: {
            resource: 'users',
            instanceId: 'users_all',
            displayField: 'toString',
            editDisplayField: 'fullNameWithTeam',
            links: true,
            linkPath: (entity) => `/resource/users/${entity.id}/detail`,
            multi: true,
            endpoints: {
                getAll: 'users/all-users/all',
            },
        },
        bulk: true,
        helperText: 'Name of the recipient(s) of the notice',
        ...fieldDefaultParams,
    },
    noticeOwnerTeams: {
        title: 'Notice owner teams',
        type: 'entityAsync',
        params: {
            resource: 'scopes',
            instanceId: 'scopes_not',
            displayField: 'title',
            links: true,
            linkPath: (entity) => `/resource/scopes/${entity.id}/detail`,
            multi: true,
            orExistsMultiFilterNotAllowed: true,
            endpoints: {
                getAll: 'scopes/all-scopes/not',
            }
        },
        helperText: 'Team(s) of the notice owner(s) (based on group repository)',
        ...fieldDefaultParams,
    },
    models: {
        title: 'Models',
        type: 'model',
        canonicalFieldName: 'findings.models',
        params: {
            resource: 'models',
            instanceId: 'allModels',
            displayField: 'toString',
            links: true,
            multi: true,
            orExistsMultiFilterNotAllowed: true,
            linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
            endpoints: {
                getAll: 'models/all-models',
            },
            // recherche floue  ??
        },
        helperText: 'IDs of the models affected by the notice',
        /** Pour empêcher MRM de modifier */
        edit: (field, value, onChange, entity, _routeParams) => {
                return ModelProvider.getDisplay(field, value, entity, {});
        },
        ...fieldDefaultParams,
    },
    status: {
        title: 'Status',
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_NOTICE_STATUS,
            multi: false,
        },
        bulk: true,
        helperText: 'Progress status of the notice (Draft, Open, Under review, Closed / Cancelled)',
        ...fieldDefaultParams,
    },
    hasUpcomingValidationForClosure: {
        title: "Upcoming validation for closure",
        type: "bool",
        ...fieldDefaultParams,
    },
    upcomingReview: {
        title: 'Linked upcoming review',
        type: 'entity',
        params: {
            resource: 'reviews',
            displayField: 'toString',
            links: true,
            multi: false,
            linkPath: (entity) => '/resource/reviews/' + entity.id + '/detail',
        },
        ...fieldDefaultParams,
        doNotResetValueWhenNotDisplayed: true,
    },
    breach: {
        title: 'Delay status',
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_NOTICE_BREACH,
            multi: false,
            orExistsMultiFilterNotAllowed: true,
        },
        helperText: 'Delay indicator : on-time, delayed or breach (alert in MRM Committee)',
        ...fieldDefaultParams,
    },
    delay: {
        title: 'Delay (number of days)',
        type: 'int',
        helperText: 'Number of days of delay (elapsed since the deadline)',
        ...fieldDefaultParams,
        displayConditions: (entity, parentState, key, context_, fieldId) => displayConditions(entity, parentState, key, context_, fieldId)
    },
    issuanceDate: {
        title: 'Committee Issuance date',
        type: 'date',
        bulk: true,
        helperText: 'Date of the committee that initially confirmed the notice',
        ...fieldDefaultParams,
        editConditions: () => false, // Même MRM ne peut modifier
    },
    statusDraftToProgressDate: {
      title: 'Confirmation date',
      type: 'date',
      helperText: 'Date when the notice went from draft status to open status',
      ...fieldDefaultParams,
      editConditions: () => false, // Même MRM ne peut modifier
    },
    deadline: {
        title: 'Deadline',
        type: 'date',
        bulk: true,
        params : {
            minDate : new Date(),
            minDateMessage:  "The notice deadline can't precede the committee issuance date",
        },
        helperText: 'Due date for completion',
        ...fieldDefaultParams,
    },
    revisedDeadline: {
        title: 'Revised deadline',
        type: 'date',
        ...fieldDefaultParams,
        displayConditions: (entity, _p, _k, _c, fieldId) =>
            entity.revisedDeadline
            && displayConditions(entity, _p, _k, _c, fieldId),
        doNotResetValueWhenNotDisplayed: true,
        params: {
            minDateMessage:  'The new proposed deadline should not precede the deadline',
        },
        helperText: (entity) => {
            let helper = [];
            if (entity.revisedDeadline) {
                if (
                    entity.revisedDeadlineWaitingForApproval === false
                ) {
                    helper.push({
                        type: 'success',
                        icon: 'fa-check',
                        text: 'Approved by LoD1 + LoD2',
                        key: 'helper-approved-by-lod1-lod2'
                    });
                } else if (entity.revisedDeadlineAuthorRole === 'IS_VALIDATOR') {
                    helper.push({
                        type: 'warning',
                        icon: 'fa-hourglass',
                        text: 'Waiting for LoD1',
                        key: 'helper-approved-by-lod1'
                    });
                    helper.push({
                        type: 'success',
                        icon: 'fa-check',
                        text: 'Approved by LoD2',
                        key: 'helper-approved-by-lod2'
                    });
                } else if (entity.revisedDeadlineAuthorRole === 'IS_OWNER') {
                    helper.push({
                        type: 'success',
                        icon: 'fa-check',
                        text: 'Approved by LoD1',
                        key: 'helper-approved-by-lod1'
                    });
                    helper.push({
                        type: 'warning',
                        icon: 'fa-hourglass',
                        text: 'Waiting for Lod2',
                        key: 'helper-approved-by-lod2'
                    });
                }
            }
            return helper;
        },
    },
    revisedDeadlineJustification: {
        title: 'Postponement justification',
        type: 'textarea',
        ...fieldDefaultParams,
        displayConditions: (entity, _p, _k, _c, fieldId) =>
            entity.revisedDeadline
            && displayConditions(entity, _p, _k, _c, fieldId),
        required: (entity, fieldId) => entity.revisedDeadline && requiredByStatus(entity, fieldId),
    },
    deliveryDate: {
        title: 'Delivery date',
        type: 'date',
        helperText: 'Delivery date of LoD 1 documentation in order to close the notice',
        doNotResetValueWhenNotDisplayed: true,
        ...fieldDefaultParams,
        // on écrase le displayConditions de fieldDefaultParams :
        displayConditions: (entity, b, c, context, fieldId) => displayConditions(entity, b, c, context, fieldId),
    },
    effectiveClosingDate: {
        title: 'Closing date',
        type: 'date',
        bulk: true,
        helperText: 'Closing date of the notice following LoD 2 validation',
        params: {}, //Ne pas supprimer, sert au minDate dans le onUpdate
        /** Avec le nouveau editConditions, sert à empêcher la modification à MRM, sauf si la notice est closed. */
        edit: (field, value, onChange, entity, routeParams, operation, bulkEntities = []) => {
            if(bulkEntities.length){
                let authorizeBulkEdit = true;
                bulkEntities.forEach((bulkEntity) => {
                    if(bulkEntity.status !== ParameterStore('NOTICE_STATUS_CLOSED')){
                        authorizeBulkEdit = false;
                    }
                });
                return authorizeBulkEdit ? DateProvider.getEdit(field, value, onChange, entity, routeParams) : DateProvider.getDisplay(field, value, entity, {});
            }
            if (userHasRoleMRM() && entity.status ===  ParameterStore('NOTICE_STATUS_CLOSED'))
                return DateProvider.getEdit(field, value, onChange, entity, routeParams);
            return DateProvider.getDisplay(field, value, entity, {});
        },
        ...fieldDefaultParams,
        // on écrase le displayConditions de fieldDefaultParams :
        displayConditions: (entity, e2, key, context, fieldId) => displayConditions(entity, e2, key, context, fieldId), // on écrase la condition par défaut
    },
    progress: {
        title: 'Progress (%)',
        type: 'int',
        bulk: true,
        helperText: 'Complete (100%)\n' +
            'Remediation work is complete, documented, attached and verified by the validation team\n' +
            '\n' +
            'Advanced (~75%)\n' +
            'Remediation work is mostly complete where all the results have been derived and documentation (if needed) is almost complete and communicated to the validation team\n' +
            '\n' +
            'Partial (~50%)\n' +
            'Remediation work has started with the verification of appropriate data, testing and generation of results\n' +
            '\n' +
            'Initiated (~25%)\n' +
            'Preliminary work has started: identification of the data and resources needed, creation of a test plan etc.   \n' +
            '\n' +
            'None (0%)\n' +
            'Not initiated',
        edit: (field, value, onChange, entity, routeParams, _operation, _bulkEntities = []) => {
            if(
                userHasIssuerRights(User.getId(), entity)
                || userIsVal(User, entity)
                || userHasNoticeOwnershipRights(User.getId(), entity)
                || userHasRoleMRM()
            ){
                return IntProvider.getEdit(field, value, onChange, entity, routeParams);
            }else{
                return IntProvider.getDisplay(field, value, entity, {});
            }
        },
        ...fieldDefaultParams,
    },
    noticeDescription: {
        title: 'Notice description',
        type: 'textarea',
        helperText: 'Detailed description of the notice',
        ...fieldDefaultParams,
    },
    expectedDeliverables: {
        title: 'Expected deliverables',
        type: 'textarea',
        helperText: 'Description of the expected elements resulting from the action plan execution',
        ...fieldDefaultParams,
    },
    actionPlan: {
        title: 'Action plan',
        type: 'textarea',
        helperText: 'Description of the action plan proposed by LoD 1 in order to remediate the notice',
        ...fieldDefaultParams,
    },
    perimeter: {
        title: 'Perimeter',
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_NOTICE_PERIMETER,
            multi: false,
        },
        bulk: true,
        helperText: 'Perimeter category for reporting purposes : ALM, Credit, IFRS9, Market...\n' +
            'Please contact MRM in order to add a new value to the list',
        ...fieldDefaultParams,
    },
    segment: {
        title: 'Segment',
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_NOTICE_SEGMENT,
            multi: false,
        },
        bulk: true,
        helperText: 'Segment category for reporting purposes: ALM, Bank, Retail, Corporate, FS - FSI…\n' +
            'Please contact MRM in order to add a new value to the list',
        ...fieldDefaultParams,
    },
    entity: {
        title: 'Entity',
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_NOTICE_ENTITY,
            multi: false,
        },
        bulk: true,
        helperText: 'Group entity affected by the notice:\n' +
            'BPCE, Natixis, Casden, Palatine, CFF…',
        ...fieldDefaultParams,
    },
    findingMraRelations: {
        title: 'Finding: Mra dimension → Mra Sub Dimension',
        type: 'entity',
        params: {
            resource: 'finding_mra_relations',
            displayField: 'toStringWithFinding',
            multi: true,
        },
        ...fieldDefaultParams,
        issueButton: false,
        helperText: 'Readonly, please modify directly on related Findings page',
        edit: (field, value, onChange, entity, _routeParams) => {
            return false;
            /** Edit desactivé pour l'instant */
            /* if (!entity || !entity.findings) return <></>;
            
            return <EditFindingMraRelationsFromNotice field={field} entity={entity} onChange={onChange} value={value} /> */
        },
    },
    comments: {
        title: 'Validation follow-up',
        type: 'textarea',
        helperText: 'Comments on the follow-up by the validation team',
        ...fieldDefaultParams,
    },
    deliveryComment: {
        title: 'Delivery comment',
        type: 'textarea',
        helperText: 'Comment provided by LoD 1 when completing the action',
        ...fieldDefaultParams,
    },
    closingComment: {
        title: 'Closing comment',
        type: 'textarea',
        helperText: 'Comment following the validation of the notice closing by LoD 2',
        ...fieldDefaultParams,
        /** Pour les rôles qui peuvent éditer ce champ (y compris MRM), on le cache systématiquement sauf si la notice est closed. */
        edit: (field, value, onChange, entity, routeParams) => {
            if (entity.status !==  ParameterStore('NOTICE_STATUS_CLOSED'))
                return null;
            return TextareaProvider.getEdit(field, value, onChange, entity, routeParams);
        },

    },
    dependsNotices: {
        title: 'Depends on',
        type: 'entity',
        helperText: 'Please fill in this field if the completion of another notice is necessary before responding to this notice.',
        params: {
            resource: 'notices',
            displayField: 'toString',
            multi: true,
            links: true,
            endpoints: {
                getAll: 'notices/all-notices',
            },
            neededFields: ['models'],
            filters: (notice, entity, _key, _context) => {
                if(notice && entity && notice.id && notice.id === entity.id){//Ne peut pas dépendre d'elle même
                    return null;
                }
                if(!entity){
                    return notice;
                }
                if(
                    Array.isArray(notice.models)
                    && Array.isArray(entity.models)
                    && notice.models.some((model) => entity.models.includes(model))
                ){
                    return notice;
                }
                return null;
            }
        },
        ...fieldDefaultParams
    },
    prerequisiteForNotices: {
        title: 'Prerequisite for',
        type: 'entity',
        helperText: 'Notice(s) blocked by the completion of this notice.',
        params: {
            resource: 'notices',
            displayField: 'toString',
            multi: true,
            links: true,
            endpoints: {
                getAll: 'notices/all-notices',
            },
            neededFields: ['models'],
            filters: (notice, entity, _key, _context) => {
                if(notice && entity && notice.id && notice.id === entity.id){//Ne peut pas dépendre d'elle même
                    return null;
                }
                if(!entity){
                    return notice;
                }
                if(
                    Array.isArray(notice.models)
                    && Array.isArray(entity.models)
                    && notice.models.some((model) => entity.models.includes(model))
                ){
                    return notice;
                }
                return null;
            }
        },
        ...fieldDefaultParams
    },
    documentsEntities: {
        title: 'Documents',
        display: (field, value, entity, _props) => (
            <DocumentManager
                values={value}
                entity={entity}
                entityResource={'notices'}
                allowedAction={(entity, document, action) => {
                    if (action === DOCUMENT_ACTION_ADD){
                        return userHasNoticeOwnershipRights(User.getId(), entity)
                            || isMemberOfNoticeOwnerTeams(entity)
                            || isValOfNotice(entity)
                            || userHasRoleMRM()
                            ;
                    }else if(action === DOCUMENT_ACTION_SHOW || action === DOCUMENT_ACTION_LIST){
                        return entity.amIModelOwner
                            || entity.amIModelOwnerDelegated
                            || entity.amIMemberOfDeveloperTeam
                            || userHasNoticeOwnershipRights(User.getId(), entity)
                            || (isMemberOfNoticeOwnerTeams(entity) && amIAuthor(document))
                            || isValOfNotice(entity)
                            || userHasRoleIG()
                            || userHasRoleMRM()
                            ;
                    }else if(action === DOCUMENT_ACTION_DELETE){
                        return isValOfNotice(entity)
                            || userHasRoleMRM()
                            || (amIAuthor(document) && (
                                userHasNoticeOwnershipRights(User.getId(), entity)
                                || isMemberOfNoticeOwnerTeams(entity)
                                || entity.amIMemberOfDeveloperTeam
                                || entity.amIModelOwner
                                || entity.amIModelOwnerDelegated
                            ))
                            ;
                    }
                    return false;
                }}
            />
        ),
        displayList: () => null,
        ...fieldDefaultParams,
    },
    findingsEntities: {
        title: 'Findings',
        display: (field, value, entity, _props, resourceComponent) => {
            let nonFilteredValues = value;
            value = [];
            if(nonFilteredValues){
                nonFilteredValues.forEach((finding) => {
                    if (
                        (finding.status === ParameterStore(FINDING_STATUS_DRAFT) && (User.profile.isMemberOfValidatorTeam || userHasRoleMRM()))
                        || finding.status !== ParameterStore(FINDING_STATUS_DRAFT)
                    ) {
                        value.push(finding);
                    }
                })
            }
            return <TableDisplay
                    rows={value}
                    cols={[
                        {
                            label: 'ID',
                            field: 'id',
                        },
                        {
                            label: 'Status',
                            field: 'statusString',
                        },
                        {
                            label: 'Title',
                            field: 'title',
                        },
                        {
                            label: 'Severity',
                            field: 'severityString',
                        },
                    ]}
                    actions={(value) => {
                        return (
                            <div>
                                {
                                    entity.amIModelOwner
                                    || entity.amIMemberOfModelOwnerTeam
                                    || entity.amIModelOwnerDelegated
                                    || userHasNoticeOwnershipRights(User.getId(), entity)
                                    || entity.amIMemberOfDeveloperTeam
                                    || isValOfNotice(entity)
                                    || isValOfFinding(value)
                                    || isMemberOfNoticeOwnerTeams(entity)
                                    || entity.amIBusinessSponsor
                                    || userHasRoleMRM()
                                    || userHasSpecificRole(Role.IG)
                                        || User.profile.isMemberOfValidatorTeam
                                    ?
                                        <OpenModal
                                            instanceId={'findings'}
                                            id={value.id}
                                            context={CONTEXT_DETAIL}
                                            modalTitle={value.title}
                                            //Pour le context d'édition ou en cas de d'édition depuis la modale detail
                                            parentInstanceId="notices"
                                            parentId={entity.id}
                                            postSaveRedirectUrl={`/resource/${_resource.instanceId}/${entity.id}/detail?tab=Notices`}
                                            parentResourceComponent={resourceComponent}
                                        />
                                : null}
                                {(
                                    value.status !== ParameterStore(FINDING_STATUS_DELETED)
                                    && value.status !== ParameterStore(FINDING_STATUS_CLOSED)
                                    && value.status !== ParameterStore(FINDING_STATUS_DISMISSED)
                                    && isValOfNotice(entity)
                                )
                                || userHasRoleMRM()
                                    ?
                                    <OpenModal
                                        parentInstanceId="notices"
                                        parentId={entity.id}
                                        instanceId="findings"
                                        id={value.id}
                                        context={CONTEXT_EDIT}
                                        modalTitle={value.title}
                                        postSaveRedirectUrl={'/resource/notices/'+entity.id+'/detail?tab=Findings'}
                                        parentResourceComponent={resourceComponent}
                                    />
                                    : null}
                            </div>
                        );
                    }}
                    buttons={
                        userHasRoleMRM() ? [
                            {
                                label: 'New finding',
                                to: `/resource/findings/add/notice/${entity.id}`,
                                icon: 'fa-plus',
                            },
                        ]
                    : []
                    }
                />
        },
        displayList: () => null,
        ...fieldDefaultParams,
    },
    mitigationActionsEntities: {
        title: 'Mitigation actions',
        display: (field, value, entity, _props) => (
            <TableDisplay
                rows={value}
                cols={[
                    {
                        label: 'Status',
                        field: 'statusString',
                    },
                    {
                        label: 'Mitigation measure type',
                        field: 'mitigationMeasureTypeString',
                    },
                    { label: 'Title', field: 'title' },
                    { label: 'Description', field: 'description' },
                    {
                        label: 'Start date',
                        field: 'startDate',
                    },
                    {
                        label: 'Covered models',
                        field: 'coveredModels',
                        type: 'entity',
                        params: {
                            resource: 'models',
                            displayField: 'functionalID',
                            links: true,
                            multi: true,
                        },
                    },
                    {
                        label: 'Documents',
                        field: 'documents',
                        type: 'entity',
                        params: {
                            resource: 'documents',
                        },
                        noTooltip: true,
                        style: { minWidth: '540px' },
                        display: (field, value, entity, _props) => (
                            <DocumentListAccordion entity={entity} values={value} />
                        ),
                    },
                ]}
                actions={(value) => {
                    return (
                        <div>
                            {
                                userHasNoticeOwnershipRights(User.getId(), entity)
                                || isValOfNotice(entity)
                                || userHasRoleMRM()
                                ? (
                                        <OpenModal
                                            instanceId={'mitigation_actions'}
                                            id={value.id}
                                            context={CONTEXT_DETAIL}
                                            modalTitle={value.title}
                                        />
                            ) : null}
                            {userHasNoticeOwnershipRights(User.getId(), entity)
                            || isValOfNotice(entity)
                            || userHasRoleMRM() ? (
                                <OpenModal
                                    parentInstanceId="notices"
                                    parentId={entity.id}
                                    instanceId="mitigation_actions"
                                    id={value.id}
                                    context={CONTEXT_EDIT}
                                    modalTitle={value.title}
                                    postSaveRedirectUrl={'/resource/notices/'+entity.id+'/detail?tab=Mitigation actions'}
                                />
                            ) : null}
                        </div>
                    );
                }}
                buttons={
                    userHasNoticeOwnershipRights(User.getId(), entity)
                    || isValOfNotice(entity)
                    || userHasRoleMRM()
                        ? [
                            {
                                label: 'New mitigation action',
                                icon: 'fa-plus',
                                inModal: true,
                                modalProps: {
                                    context: CONTEXT_ADD,
                                    instanceId: 'mitigation_actions',
                                    parentInstanceId: 'notices',
                                    routeParams: { noticeId: entity.id },
                                    parentId: entity.id,
                                    postSaveRedirectUrl: `/resource/notices/${entity.id}}/detail?tab=Mitigation actions`
                                }
                            },
                        ]
                        : []
                }
            />
        ),
        displayList: () => null,
        ...fieldDefaultParams,
    },
    changeLogsEntities: {
        title: 'Audit trail',
        ...fieldDefaultParams,
        display: (field, value, entity, props) => (
            <ChangeLog
                field={field}
                values={value}
                entity={entity}
                entityResource={'notices'}
                props={props}
            />
        ),
        displayList: () => null,
    },
    validatorComments: {
        title: 'LoD2 Internal Comments (visible by LoD 2 only)',
        type: 'textarea',
        helperText: 'Field visible by LoD 2 only',
        ...fieldDefaultParams,
        displayList: () => null,
        /**
         * Attention, comme ce champ n'est pas visible du NoticeOwner, au moment où
         * il va confirmer reception, il va quand même envoyer un apiPut, mais par défaut
         * APIResource écrase les champs non affichés. Nous on veut le conserver.
         */
        doNotResetValueWhenNotDisplayed: true,
    },
    readOwnershipNotification: {
        title: "Receipt",
        type: "bool",
        ...fieldDefaultParams,
    },
    blockingNotice: {
        title: 'Blocking notice',
        type: 'bool',
        helperText: 'Notice prohibiting the model usage until its closure.',
        doNotResetValueWhenNotDisplayed: true,
        ...fieldDefaultParams,
        // on écrase le displayConditions de fieldDefaultParams :
        displayConditions: (entity, b, c, context, fieldId) =>
            displayConditions(entity, b, c, context, fieldId) &&
            ['FINDING_SEVERITY_BPCE_1', 'FINDING_SEVERITY_BPCE_2', 'FINDING_SEVERITY_NTX_2', 'FINDING_SEVERITY_NTX_3']
                .map((s) => getParamBySystemId(s)?.['@id'])
                .includes(entity.noticeSeverity),
    },

    //Additional fields without relation with Notice
    changeLogComment: {
        ...fieldDefaultParams,
        title: 'Justification of the data update',
        type: 'textarea',
        display: (_field, _value, _entity, _props) => null,
        displayList: (_field, _value, _entity, _props) => null,
        token: false,
    },
    messages: {
        ...fieldDefaultParams,
        title: 'Chat',
        display: (value, props, entity) => (
            <>
                <Banner>
                    {userHasInChargeRights(User.getId(), entity) && 'You can use the below chat to communicate with the Notice Owner.'}
                    {userHasNoticeOwnershipRights(User.getId(), entity) &&
                        'You can use the below chat to communicate with the Validators in charge of the follow-up.'}
                </Banner>
                <Thread
                    title=""
                    entityId={entity.id}
                    entityType="notice"
                    readOnly={
                        !userHasNoticeOwnershipRights(User.getId(), entity)
                        && !userHasIssuerRights(User.getId(), entity)
                        && !userHasInChargeRights(User.getId(), entity)
                    }
                />
            </>
        ),
        displayList: () => null,
        token: false,
    },
});

const NOTICE_LAYOUT = {
    tabs: {
        General: {
            rows: [
                {
                    panels: {
                        Identification: {
                            cols: 6,
                            fields: [
                                'id',
                                'initialId',
                                'title',
                                'noticeSeverity',
                                'reviews',
                                'models',
                                'blockingNotice',
                            ],
                        },
                        Stakeholders: {
                            cols: 6,
                            fields: [
                                'noticeIssuer',
                                'noticeIssuerTeams',
                                'noticeValidators',
                                'validatorsInChargeOfTeams',
                                'noticeOwners',
                                'noticeOwnerTeams',
                            ],
                        },
                    },
                },
                {
                    panels: {
                        Description: {
                            cols: 6,
                            fields: [
                                'noticeDescription',
                                'expectedDeliverables',
                                'actionPlan',
                                'perimeter',
                                'segment',
                                'entity'
                            ],
                        },
                        'Risk dimensions': {
                            cols: 6,
                            fields: [
                                'findingMraRelations',
                            ],
                        },
                        Planning: {
                            cols: 6,
                            fields: [
                                'status',
                                'hasUpcomingValidationForClosure',
                                'upcomingReview',
                                'issuanceDate',
                                'statusDraftToProgressDate',
                                'deadline',
                                'revisedDeadline',
                                'breach',
                                'delay',
                                'deliveryDate',
                                'effectiveClosingDate',
                                'progress',
                            ],
                        },
                        'Follow-up': {
                            cols: 6,
                            fields: [
                                'comments',
                                'validatorComments',
                                'deliveryComment',
                                'revisedDeadlineJustification',
                                'closingComment',
                            ],
                        },
                        'Dependencies': {
                            cols: 6,
                            fields: [
                                'dependsNotices',
                                'prerequisiteForNotices',
                            ],
                        },
                    },
                },
            ],
        },
        Documents: {
            rows: [
                {
                    panels: {
                        Documents: {
                            cols: 12,
                            fields: ['documentsEntities'],
                        },
                    },
                },
            ],
        },
        Chat: {
            rows: [
                {
                    panels: {
                        'Chat': {
                            cols: 12,
                            fields: ['messages'],
                        },
                    },
                },
            ],
        },
        Findings: {
            rows: [
                {
                    panels: {
                        'Findings': {
                            cols: 12,
                            fields: ['findingsEntities'],
                        },
                    },
                },
            ],
        },
        'Mitigation actions': {
            rows: [
                {
                    panels: {
                        'Mitigation actions': {
                            cols: 12,
                            fields: ['mitigationActionsEntities'],
                        },
                    },
                },
            ],
        },
        'Audit trail': {
            rows: [
                {
                    panels: {
                        'Audit trail': {
                            cols: 12,
                            fields: ['changeLogsEntities', 'changeLogComment'],
                        },
                    },
                },
            ],
        },
    },
};

const additionalActionButtonsList = (resource) => {
    let additionalActionButtons = [];

    if (resource.instanceId !== "my_notices"){
        if (userHasRoleMRM() || userHasRoleADMIN()){
            additionalActionButtons.push({
                tooltip: 'Import',
                icon: 'download',
                className: 'download',
                onClick: () => Modal.open({
                    title: "Import",
                    content: (
                        <EntityImportForm resource="notices" />
                    ),
                    size: "small"
                }),
            })
        }

        additionalActionButtons.push(
            {
                tooltip: 'Full export',
                icon: 'upload',
                className: 'upload',
                onClick: () => Modal.open({
                    title: "Export",
                    content: (
                        <EntityExportForm
                            resource="notices"
                            withDate={userHasRoleMRM() || userHasRoleADMIN() || User.profile.isMemberOfValidatorTeam}
                            showModelLevelCheckbox={true}
                        />
                    ),
                    size: "small"
                }),
            })
    }

    if(
        User.profile.isMemberOfValidatorTeam
        || userHasRoleMRM()
    ){
        const choices = [
            {
                link: '/resource/findings/add',
                title: 'New finding',
            },
        ];

        userHasRoleMRM() &&
        choices.unshift({
            link: '/resource/notices/add',
            title: 'New notice',
        });
        additionalActionButtons.push({
            onClick: () =>
                Modal.open({
                    title: userHasRoleMRM() ? 'New' : 'Declare a new Issue',
                    content: (
                        <>
                            <ChoiceModal choices={choices} />
                            {userHasRoleMRM() || (
                                <p>
                                    Please note that in order to create a new Notice, you must start
                                    with creating at least one Finding. Then, you will be able to
                                    create a new Notice directly on the Finding Sheet.
                                </p>
                            )}
                        </>
                    ),
                    size: "small",
                }),
            tooltip: 'New',
            icon: 'plus',
            className: 'primary',
        });
    }

    return additionalActionButtons;
};

//validatorTeams: important pour conserver un calcul correct des droits lors de l'enregistrement de la notice
const FIELDS_SAVE_WHITE_LIST = ['origin', 'findings', 'validatorTeams', 'reviews', 'absenceOfMRAUpdate'];

export const ApiResourceDefaultParams = {
    id: 'notices',
    fieldForTitle: 'title',
    breadcrumbName: "Notice",
    componentForTitle: (entity, resource, resourceEditComponent) => {
        return <Breadcrumb entity={entity} resource={resource} resourceEditComponent={resourceEditComponent} relationalProperty={'models'} resourcePath={'models'} />
    },
};

export default class NoticeAdmin {
    constructor() {
        this.configure().then(_r => {});
    }

    async configure() {
        await User.restore(true);

        const views = [
            {
                instanceId: 'notices',
                name: 'Notices',
                additionalActionButtonsList: additionalActionButtonsList,
                permanentFilters: null,
                bulkEditable: userHasRoleMRM(),
                bulkDeletable: userHasRoleMRM(),
            },
            {
                instanceId: 'my_notices',
                name: 'My notices',
                additionalActionButtonsList: additionalActionButtonsList,
                permanentFilters: {
                    my_notices: true,
                },
                bulkEditable: userHasRoleMRM(),
                bulkDeletable: userHasRoleMRM(),
            },
            {
                instanceId: 'deleted_notices',
                name: 'Deleted notices',
                permanentFilters: {
                    deleted: true
                },
            },
        ];
        views.forEach((view) => {
            const resource = new APIResource({
                ...ApiResourceDefaultParams,
                ...{
                    instanceId: view.instanceId,
                    name: view.name,
                }
            });
            resource
                .setFields(NOTICE_FIELDS(resource))
                .setLayout(NOTICE_LAYOUT)
                .genListView({
                    fields: [
                        'id',
                        'models',
                        'title',
                        'noticeDescription',
                        'status',
                        'noticeSeverity',
                        'deadline',
                        'noticeValidators',
                        'noticeOwners',
                    ],
                    showDefaultAddButton: false,
                    permanentFilters: view.permanentFilters,
                    additionalActionButtons: view.additionalActionButtonsList
                })
                .genInsertView({
                    menuItem: {title: 'Add'},
                    additionalRoutes: [
                        '/resource/notices/add/model/:modelId',
                        '/resource/notices/add/review/:reviewId/:reviewInstanceId',
                        '/resource/notices/add/finding/:findingId',
                        '/resource/notices/add/finding/:findingId/:reviewId',
                    ],
                    fields: [
                        'initialId',
                        'title',
                        'reviews',
                        'noticeSeverity',
                        'noticeIssuer',
                        'noticeValidators',
                        'noticeOwners',
                        'models',
                        'blockingNotice',
                        'status',
                        'issuanceDate',
                        'deadline',
                        'deliveryDate',
                        'effectiveClosingDate',
                        'progress',
                        'noticeDescription',
                        'expectedDeliverables',
                        'actionPlan',
                        'perimeter',
                        'segment',
                        'entity',
                        'comments',
                        'validatorComments',
                        'changeLogComment',
                        'dependsNotices',
                        'prerequisiteForNotices',
                    ],
                    postSaveRedirect: 'detail',
                    fieldsSaveWhitelist: FIELDS_SAVE_WHITE_LIST,
                    onInit: async ({entity, routeParams}) => {
                        entity['@type'] = 'Notice';
                        entity.status = ParameterStore('NOTICE_STATUS_DRAFT');
                        entity.noticeIssuer = `/api/users/${User.getId()}`;
                        entity.noticeValidators = [entity.noticeIssuer];

                        entity.findings = routeParams.findingId ? ['/api/findings/' + routeParams.findingId] : entity.findings;
                        entity.reviews = routeParams.reviewId ? ['/api/reviews/' + routeParams.reviewId] : entity.reviews;
                        if (!entity.reviews) entity.reviews = [];
                        // On set les models pour être affichés uniquement
                        if(!entity.models){
                            entity.models = [];
                        }else{
                            entity.modelEntity = getModel(entity.models[0]);
                        }
                        if(routeParams.reviewId && entity.reviews && !routeParams.findingId){
                            entity.origin = ORIGIN_REVIEW;
                            entity.hasOriginReview = true;
                        }
                        if(entity.findings && Array.isArray(entity.findings) && entity.findings.length > 0 && routeParams.findingId){
                            const findings = await Promise.all(entity.findings.map(findingPath => APIResourceStore.resources.findings.getItemFromResourcePath(findingPath, true)));
                            findings.forEach((finding) => {
                                    if(finding.models){
                                        finding.models.forEach((modelPath) => {
                                            // On remplit les models à titre d'information, car ils sont portés par Finding en réalité.
                                            if(!entity.models.includes(modelPath)){
                                                entity.models.push(modelPath);
                                            }
                                        });
                                    }
                                    // On prend les reviews du finding qui a servi à créer cette notice
                                    if(finding.id === parseInt(routeParams.findingId) && finding.reviews){
                                        entity.reviews = [...finding.reviews];
                                    }
                            })
                        }
                    },
                })
                .genEditView({
                    fields: [
                        'initialId',
                        'title',
                        'reviews',
                        'noticeSeverity',
                        'noticeIssuer',
                        'validatorsInChargeOfTeams',
                        'noticeValidators',
                        'noticeOwners',
                        'models',
                        'blockingNotice',
                        'status',
                        'upcomingReview',
                        'issuanceDate',
                        'deadline',
                        'revisedDeadline',
                        'revisedDeadlineJustification',
                        'deliveryDate',
                        'effectiveClosingDate',
                        'progress',
                        'noticeDescription',
                        'expectedDeliverables',
                        'actionPlan',
                        'perimeter',
                        'segment',
                        'entity',
                        'findingMraRelations',
                        'comments',
                        'validatorComments',
                        'deliveryComment',
                        'closingComment',
                        'changeLogComment',
                        'dependsNotices',
                        'prerequisiteForNotices',
                    ],
                    fieldsSaveWhitelist: FIELDS_SAVE_WHITE_LIST,
                    itemAccessCondition: (entity) =>
                        (userHasRoleMRM() || (
                            entity.status !== ParameterStore('NOTICE_STATUS_DELETED')
                            && entity.status !== ParameterStore('NOTICE_STATUS_CLOSED')
                            && entity.status !== ParameterStore('NOTICE_STATUS_DISMISSED')
                        ))
                        && !userHasSpecificRole(Role.IG)
                        && ( // noticeOwners ne peut pas modifier
                            isValOfNotice(entity)
                            || userHasRoleMRM()
                        )
                    ,
                    onUpdate: (fieldId, _oldValue, newValue, _entity, resource, _resourceEditComponent) => {
                        /* // on ne veut plus systématiquement mettre à jour les models depuis la review
                        // on le fait uniquement dans le onInit en cas de review déjà remplie.
                        if (fieldId === 'reviews' && newValue) {
                            resourceReviews.getItemFromResourcePath(newValue).then((review) => {
                                if (!entity.models) {
                                    entity.models = [];
                                }
                                entity.models = entity.models.concat(review.models);
                                resourceEditComponent.setState({entity});
                            });
                        } */

                        if (fieldId === 'deliveryDate') {
                            resource.fields.effectiveClosingDate.params.minDate = newValue;
                            delete resource.fields.effectiveClosingDate.params.maxDate;
                            if (!newValue) {
                                delete resource.fields.effectiveClosingDate.params.minDate;
                            }
                        }
                        if (fieldId === 'deadline') {
                            resource.fields.revisedDeadline.params.minDate = newValue;
                            if (!newValue) {
                                delete resource.fields.revisedDeadline.params.minDate;
                            }
                        }
                    },
                    header: header,
                    onInit: ({entity, resource, context}) => {

                      if(entity.issuanceDate)
                      {
                        resource.fields.deadline.params.minDate = entity.issuanceDate;
                      }
                      else {
                        resource.fields.deadline.params.minDate = new Date();
                      }
                      if (entity.deadline) {
                        resource.fields.revisedDeadline.params.minDate = entity.deadline;
                      }

                      if (
                            GetEnvironmentUriParameter('cr') === 'true'
                        ) {
                            Navigation.router.history.push({
                                pathname: Navigation.router.pathname,
                                search: '', //Reset get param
                            });
                            noticeConfirmReceipt(resource, entity, context);
                        }
                        if (
                            GetEnvironmentUriParameter('missing-finding') === 'true'
                        ) {
                            noticeMissingFieldsAndFinding();
                        }
                    },
                    additionalLinkButton: additionalLinkButton,
                })
                .genDetailView({
                    additionalActionButtons: additionalActionButtons,
                    additionalLinkButton: additionalLinkButton,
                    header: header,
                    itemAccessCondition: (entity) => {
                        return entity.amIModelOwner
                            || entity.amIModelOwnerDelegated
                            || userHasNoticeOwnershipRights(User.getId(), entity)
                            || entity.amIMemberOfDeveloperTeam
                            || isValOfNotice(entity)
                            || entity.amIBusinessSponsor
                            || userHasRoleMRM();
                    },
                })
                .allowDelete({
                    itemAccessCondition: (entity) =>
                        entity.status === ParameterStore('NOTICE_STATUS_DRAFT')
                        && (
                            userIsVal(User, entity)
                            || userHasInChargeRights(User.getId(), entity)
                        )
                        || (userHasRoleMRM() && entity.status !== ParameterStore('NOTICE_STATUS_DELETED'))
                    ,
                    component: (entity) => {
                        const noticeHasMraRelations = (entity?.findingMraRelations || []).length > 0;
                        return (
                            <LogicalDeleteButton
                                entity={entity}
                                className="tooltip-top"
                                resource={resource}
                                hideReplaceButton={true}
                                entityType={'notice'}
                                entityTypeLabel={'notice'}
                                entityTitle={entity.title}
                                successMessage={"Your notice has been deleted."}
                                {...(noticeHasMraRelations ? {onPreValidate: openAutomaticScoringInformationModal} : {})}
                            />
                        );
                    },
                })
                .setValidation((entity) => {
                    let errors = [];
                    let detailViewTab = null;

                    if(entity.status === ParameterStore("NOTICE_STATUS_IN_PROGRESS")){
                        if(entity.progress >= 100 && !userHasRoleMRM()){
                            errors.push({
                                field: "Progress",
                                detail: "You can't set a progress at 100% for a notice in progress. Please move the process forward.",
                            });
                        }
                    }

                    // Add Dates checks
                    if ((entity.deliveryDate) && (entity.effectiveClosingDate)) {
                        if (DateFormatter.toOnlyDateInUTC(entity.deliveryDate) > DateFormatter.toOnlyDateInUTC(entity.effectiveClosingDate)) {
                            errors.push({
                                field: "Delivery date",
                                detail: "The delivery date should not be later than the Closing date",
                            });
                        }
                    }

                    if ((entity.issuanceDate) && (entity.deadline)) {
                        if (DateFormatter.toOnlyDateInUTC(entity.issuanceDate) > DateFormatter.toOnlyDateInUTC(entity.deadline)) {
                            errors.push({
                                field: "Deadline",
                                detail: "The notice deadline can't precede the committee issuance date",
                            });
                        }
                    }

                    if(
                        (
                            userHasRoleMRM()
                            || userIsVal(User, entity)
                        )
                        && errors.length === 0
                    ){
                        return true;
                    }

                    if (errors.length > 0) {
                        if (detailViewTab !== null) {
                            Navigation.router.history.push(
                                `/resource/notices/${entity.id}/detail?tab=${detailViewTab}`
                            );
                        }
                        return errors;
                    }
                    return true;
                })
                .addBulkAction(EntityExport, {
                    resource: "notices",
                    icon: 'file-export',
                    label: "Export selected notices ?",
                    showModelLevelCheckbox: true,
                })
            ;

            if(view.instanceId === 'my_notices' && User.profile.isMemberOfValidatorTeam){
                resource
                    .addBulkAction(RemindNotice, {
                        resource,
                        icon: 'bell'
                    })
                    .addBulkAction(CloseNotice, {
                        resource,
                        icon: 'check-circle',
                        itemAccessCondition: (entity) =>
                            entity.status === ParameterStore('NOTICE_STATUS_WAITING_FOR_VALIDATION')
                            || entity.status === ParameterStore('NOTICE_STATUS_IN_PROGRESS')
                        ,
                        forbiddenAccessMessage:
                            "Only notices in Waiting for validation or Open can be closed.",
                    })
                ;
            }

            if (view.bulkEditable) {
                resource
                    .addBulkAction(BulkEdit, {
                        resource,
                        icon: 'edit',
                        fields: Object.fromEntries(
                            Object.entries(resource.fields)
                                .filter(([k, v]) => {
                                    return v.bulk && resource.operations.edit.fields.includes(k);
                                })
                                .map(([k, v]) => ((v.resourceId = resource.id), [k, v]))
                        ),
                    });
            }

            if (view.bulkDeletable) {
                resource
                    .addBulkAction(BulkDelete, {
                        itemAccessCondition: (entity) =>
                            entity.status === ParameterStore('NOTICE_STATUS_DRAFT')
                            && (
                                userIsVal(User, entity)
                                || userHasInChargeRights(User.getId(), entity)
                            )
                            || (userHasRoleMRM() && entity.status !== ParameterStore('NOTICE_STATUS_DELETED'))
                        ,
                        forbiddenAccessMessage: "You can't delete notices that are not in Draft status.",
                        resource,
                        icon: 'trash-alt',
                        entityType: 'notice',
                        entityTypeLabel: 'Notice',
                        softDelete: true
                    });
            }
        });
    }
}
