import Http from '../../Http';
import Alert from "../../Alert";
import React, {Component} from "react";
import Modal from "../../Modal";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {DatePicker} from "../../../Components/Forms/DatePicker/DatePicker";
import Grid from "@material-ui/core/Grid";
import {userHasRoleMRM} from "../../../Store/ParameterStore";
import {Icon} from "@material-ui/core";

export class ReviewExport{
    getId(){
        return 'reviewCsvExport'
    }

    getLabel(){
        return 'Review export'
    }

    setParameters(params){
        this.parameters = params;
    }

    run(ids, params = null){
        this.ids = ids;

        Modal.open({
            title: 'Review export',
            content: <ReviewExportForm ids={this.ids}/>
        });
    }
}


export class ReviewExportForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            progress: false,
            date: null,
            running: undefined
        };
    }

    save(){
        this.setState({progress: true});
        Http.post('reviews/export', {ids: this.props.ids, from: this.props.from }).then((response) => {
            if(response.status === 'ERROR'){
                Alert.show({ message : response.message, type: 'error'});
            }else if(response.status === 'OK'){
                if(response.route && response.fileName){
                    Http.openFile(response.route, response.fileName);
                }
            }else{
                Alert.show({ message : "File can't be downloaded", type: 'warning'});
            }
            Modal.close();
            this.setState({progress: false});
        });
    }

    render() {
        return (
            <Grid className={'export container'}>
                { this.state.progress ?
                    <div className={"export_progress_container"}>
                        <CircularProgress />
                        <p>Please wait....</p>
                    </div>
                    :
                    <div>
                        <Button style={{marginTop: 10}} variant="contained" color="secondary" className="save button-general" onClick={this.save.bind(this)}>
                            Export
                        </Button>
                    </div>
                }
            </Grid>
        )
    }
}