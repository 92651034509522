import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "../../Services/Modal";
import ParameterStore from "../../Store/ParameterStore";
import {APIResource} from "../../Services/APIResource/APIResource";
import { EntityForm } from "../Forms/EntityForm/EntityForm";
import { INDEPENDENT_IMPLEMENTATION_TESTINGS_NONE, INDEPENDENT_IMPLEMENTATION_TESTINGS_OTHER } from "../../Admin/ReviewAdmin";
import { ButtonBar } from "../Modal/ButtonBar";
import { ActionButton } from "../Modal/ActionButton";
import { ModalContent } from "../Modal/ModalContent";
import { PARAMETER_TYPE_MODEL_USE_VALIDATION_STATUS } from "../../Admin/ParameterAdmin";

export class UpdateModelUseValidationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validationStatus: null,
            validationStatusRationale: null,
            /**
             * on rajoute les champs nécessaires au filtre de this.fields.validationStatus
             * sur le modèle de {@see ReviewAdmin.REVIEW_FIELDS.validationStatus} 
             */
            hasBlockingNotice: null,
            independentImplementationTesting: null,
        };
        this.resourceReviewModelUse = new APIResource({
            id: 'review_model_uses',
        });
        // ValidationStatus et ValidationStatusRationale pour ReviewModelUse ont les mêmes
        // règles que pour Review, sauf que validationStatusRationale s'appelle "comment"
        // dans Review.
        /** @type {Object.<string, import('../../Services/APIResource/APIResource').APIResourceField>} */
        this.fields = { 
            validationStatus: {
                title: 'Validation status',
                type: 'parameter',
                issueButton: false,
                params: {
                    type: PARAMETER_TYPE_MODEL_USE_VALIDATION_STATUS,
                    multi: false,
                    filters: (parameter, review) => {
                        if (!parameter || !review) return;
                        switch (parameter['@id']) {
                            case ParameterStore('MODEL_USE_VALIDATION_STATUS_PARTIALLY_APPROVED_PENDING'):
                                // proposé uniquement lorsque le champ implementation review scope de la revue a pour valeur "Other environment" ou "None":
                                if (![INDEPENDENT_IMPLEMENTATION_TESTINGS_NONE, INDEPENDENT_IMPLEMENTATION_TESTINGS_OTHER].includes(review.independentImplementationTesting)) {
                                    return null;
                                }
                                break;
        
                            case ParameterStore('MODEL_USE_VALIDATION_STATUS_NOT_APPROVED_PENDING'):
                                // uniquement si au moins une des notices rattachées à la revue a le flag "blocking notice" défini à yes.
                                if (!review.hasBlockingNotice) return null;
                                break;
        
        
                            default:
                                break;
                        }
                        return parameter;
                    },
                },        
            }, 
            validationStatusRationale: {
                title: 'Validation status rationale',
                type: 'textarea',
            },
        }
    }

    componentDidMount() {
        this.setState({
            validationStatus: this.props.validationStatus,
            validationStatusRationale: this.props.validationStatusRationale,
            hasBlockingNotice: this.props.review.hasBlockingNotice,
            independentImplementationTesting: this.props.review.independentImplementationTesting,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.entity !== this.props.entity
            || prevProps.validationStatus !== this.props.validationStatus
            || prevProps.validationStatusRationale !== this.props.validationStatusRationale
        ) {
            this.setState({
                validationStatus: this.props.validationStatus,
                validationStatusRationale: this.props.validationStatusRationale
            });
        }
    }

    save() {
        this.setState({progress: true});

        this.props.entity.validationStatus = this.state.validationStatus;
        this.props.entity.validationStatusRationale = this.state.validationStatusRationale;

        this.resourceReviewModelUse.apiPut(this.props.entity).then(() => {
            this.props.resource.getItem(this.props.review.id, true).then((item) => {
                this.props.parentComponent.entity = item;
                this.props.parentComponent.forceUpdate();
                Modal.close();
                this.setState({progress: false});
            });
        }).catch(err => {
            console.error(err);
            Modal.close();
            this.setState({progress: false});
        });
    }

    render() {
        return (
            <ModalContent className={"model-use-review-form"}>
                <EntityForm 
                    entity={this.state}
                    fields={this.fields}
                    onUpdate={this.setState.bind(this)}
                />
                <ButtonBar>
                    <ActionButton
                        onClick={() => this.save()}
                        loading={this.state.progress}
                    >
                        Save
                    </ActionButton>
                </ButtonBar>
            </ModalContent>
        );
    }
}
UpdateModelUseValidationModal.propTypes = {
    review: PropTypes.object,
    /** instance de ReviewModelUse */
    entity: PropTypes.object,
    /** resource pour reviews */
    resource: PropTypes.object,
    validationStatus: PropTypes.string,
    validationStatusRationale: PropTypes.string,
    parentComponent: PropTypes.any,
}
