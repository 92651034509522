import React, { useEffect, useState } from 'react';
import { Detail } from './Detail';
import { Edit } from './Edit';
import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import APIResourceStore from '../../Store/APIResourceStore';
import { getIdFromIri } from '../../Services/utils';
import { MRA_PROCESS, getUserRole } from '../../Services/MRA';
import { ModalContent } from '../Modal/ModalContent';
import { observer } from 'mobx-react';

export const ChooseMraLayout = observer((props) => {
    const [loading, setLoading] = useState(true);
    const [useNewLayout, setUseNewLayout] = useState(false);
    const matchParams = useParams();

    const { detailView, ...otherProps } = props;

    useEffect(() => {
        const onLoad = async (mraId) => {
            const mra = await APIResourceStore.resources.mras.getItem(mraId);
            const process = await APIResourceStore.resources.parameters.getItem(getIdFromIri(mra.process));
            const model = await APIResourceStore.resources.models.getItemFromResourcePath(mra.model, true);                
            setUseNewLayout(process.systemId === MRA_PROCESS.LOD2 || getUserRole(model) === 'LoD1');
            // Si l'utilisateur est LoD1 on le laisse aller sur la vue Edit où
            // seront joués les tests de redirection en fonction des conflits etc.
            setLoading(false);
        };

        const mraId = parseInt(matchParams?.id);
        if (APIResourceStore.resources.mras && APIResourceStore.resources.parameters)
            onLoad(mraId);
    }, [matchParams]);

    return (
        <>
            {loading && (
                <ModalContent style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress />
                </ModalContent>
            )}
            {!loading && (useNewLayout ? <Edit {...otherProps} readonly={detailView} /> : <Detail {...otherProps} />)}
        </>
    );
});
