import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ChangeLangButton } from '../../I18n/ChangeLangButton';
import { Button, Icon, Paper } from '@material-ui/core';
import { MRA_PROCESS, MRA_STATUS, canEditByProcess } from '../../../Services/MRA';
import { DeleteMraButton, ExportMraButtons, RelatedEntitiesButtons } from '../Detail';
import { getParamByIri, getParamBySystemId } from '../../../Store/ParameterStore';
import Http from '../../../Services/Http';

export const NavigationButtons = (props) => {
    const { mra, syncMras, userRole, readonly } = props;
    const { saving, onSave } = props;
    const { loadingDimension, onSubmit, onlyReload, setForceReload } = props;

    const [nextStatus, setNextStatus] = useState(null);

    const currentStatus = getParamByIri(mra.status);
    const currentProcess = getParamByIri(mra.process);
    const isLoD1SelfAssessment = currentProcess.systemId === MRA_PROCESS.LOD1;

    const canSave =
        (currentStatus.systemId === MRA_STATUS.CONFLICT || currentStatus.systemId === MRA_STATUS.ON_GOING) && !readonly;

    useEffect(() => {
        const getNextStatus = async () => {
            let data = await Http.get('mras/' + mra.id + '/next_status');
            let systemId = data['hydra:member'][mra.id]['nextStatusSystemId'];
            setNextStatus(getParamBySystemId(systemId));
        };
        mra && getNextStatus();
    }, [mra]);

    return (
        <Paper style={styles.buttonsContainer} elevation={5} className="button-bar-bottom">
            <ChangeLangButton />
            {!readonly && (
                <Link to={'/resource/mras/' + (mra && mra.id) + '/detail'}>
                    <Button variant="contained" color="primary" className="tooltip tooltip-top" disabled={!mra}>
                        <Icon style={styles.buttonIcon} className="fa fa-th-large" />
                        <span className="tooltiptext">View details</span>
                    </Button>
                </Link>
            )}
            {readonly && (canSave || canEditByProcess(userRole, currentStatus.systemId, currentProcess.systemId)) && (
                <Link to={'/resource/mras/' + (mra && mra.id) + '/update'}>
                    <Button variant="contained" disabled={!mra} className="button-edit warning tooltip">
                        <Icon style={styles.buttonIcon} className="fa fa-edit" />
                        <span className="tooltiptext">Edit</span>
                    </Button>
                </Link>
            )}
            {(!mra || mra.open) && canSave && (
                <Button
                    variant="contained"
                    color="primary"
                    className="button-table button-show-version tooltip tooltip-top"
                    style={styles.saveButton}
                    onClick={onSave}
                    disabled={loadingDimension || saving || onlyReload}
                >
                    <Icon
                        style={styles.buttonIcon}
                        className={
                            loadingDimension || saving
                                ? 'fa fa-circle-notch text-primary fa-rotate fa-spin'
                                : 'fa fa-save'
                        }
                    />
                    <span className="tooltiptext">Save</span>
                </Button>
            )}
            {(!mra || mra.open) && onlyReload && (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setForceReload((f) => !f)}
                    style={styles.submitButton}
                    className="button-table tooltip tooltip-top"
                >
                    <Icon style={styles.buttonIcon} className={'fa fa-redo'} />
                    <span className="tooltiptext">Reload</span>
                </Button>
            )}
            {(!mra || mra.open) && canSave && !onlyReload && (!mra.review || currentProcess.systemId !== MRA_PROCESS.LOD2) && (
                /**
                 * On garde le bouton "flèche verte" pour le cas où le MRA est hors Review (et temporairement pour les anciens
                 * MRAs qui sont encore sur les process avec LoD1).
                 * {@see https://app.asana.com/0/1134038211401766/1206348402476856/f}
                 */
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => onSubmit(nextStatus)}
                    style={styles.submitButton}
                    className="button-table tooltip tooltip-top"
                    disabled={loadingDimension || !nextStatus || saving}
                >
                    <Icon
                        style={styles.buttonIcon}
                        className={
                            loadingDimension ? 'fa fa-circle-notch text-primary fa-rotate fa-spin' : 'fa fa-arrow-up'
                        }
                    />
                    <span className="tooltiptext">
                        {isLoD1SelfAssessment ? 'Submit self-assessment' : currentProcess.systemId !== MRA_PROCESS.LOD2 ? 'Close MRA' : 'Submit to LoD2'}
                    </span>
                </Button>
            )}

            {readonly && (
                <DeleteMraButton
                    mra={mra}
                    syncMras={syncMras}
                    userRole={userRole}
                    currentStatus={currentStatus}
                    disabled={saving}
                />
            )}
            {readonly && <ExportMraButtons mra={mra} mraProcess={currentProcess} />}
            <RelatedEntitiesButtons mra={mra} />
        </Paper>
    );
};
NavigationButtons.propTypes = {
    mra: PropTypes.shape({
        id: PropTypes.number,
        open: PropTypes.bool,
        status: PropTypes.string,
        process: PropTypes.string,
        review: PropTypes.string,
    }),
    syncMras: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
    userRole: PropTypes.string,
    readonly: PropTypes.bool,
    saving: PropTypes.bool,
    onSave: PropTypes.func,
    loadingDimension: PropTypes.bool,
    onSubmit: PropTypes.func,
    onlyReload: PropTypes.bool,
    setForceReload: PropTypes.func,
};

const styles = {
    buttonsContainer: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(255,255,255,1)',
        padding: '10px !important',
    },
    saveButton: {
        fontSize: 15,
        backgroundColor: '#0dbbb7',
        margin: 3,
    },
    submitButton: {
        fontSize: 15,
        backgroundColor: '#48A10D',
        margin: 3,
    },
    buttonIcon: {
        fontSize: '0.875rem',
    },
};
