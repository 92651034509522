import React, {Component} from 'react';
import {observer} from "mobx-react";
import Button from "@material-ui/core/Button";
import Alert from "../../Services/Alert";
import Http from "../../Services/Http";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
import Modal from "../../Services/Modal";
import { queryHasAttribute } from '../../Admin/common';

class ReviewExport extends Component{

    constructor(props){
        super(props);

        this.state = {
            progress: false,
            statuses: undefined,
        };
    }

    componentDidMount() {
        this.setState({date: new Date()});

        const queryParams = new URLSearchParams(window.location.search);
        if (queryHasAttribute(queryParams, 'statuses')){
            const statuses = queryParams.get('statuses').split(',');
            this.setState({statuses});
        }
    }

    save(){
        this.setState({progress: true});
        const { statuses } = this.state;
        Http.post('reviews/export', statuses ? {statuses} : {}).then((response) => {
            if(response.status === 'ERROR'){
                Alert.show({ message : response.message, type: 'error'});
            }else if(response.status === 'OK'){
                if(response.route && response.fileName){
                    Http.openFile(response.route, response.fileName);
                }else if(response.documentId && response.documentName){
                    Http.openEncryptedFile(
                        "/document/download/" +
                        response.documentId,
                        response.documentName
                    )
                }else if(response.message){
                    Alert.show({ message : response.message, type: 'success'});
                }
            }else{
                Alert.show({ message : "File can't be downloaded", type: "error"});
            }
            Modal.close();
            this.setState({progress: false});
        });
    }

    render() {
        return (
            <Grid className={'export container'}>
                <h1>Review export</h1>
                { this.state.progress ?
                    <div className={"export_progress_container"}>
                        <CircularProgress />
                        <p>Please wait....</p>
                    </div>
                    :
                    <div>
                        <Button style={{marginTop: 10}} variant="contained" color="secondary" className="save button-general" onClick={this.save.bind(this)}>
                            Export
                        </Button>
                    </div>
                }
            </Grid>
        )
    }
}

export default observer(ReviewExport);
